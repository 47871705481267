<template lang="pug">
  .products-category(v-if="content")
    .page__wr
      ul.products-category__nav(v-if="content.nav")
        li.products-category__nav-item(v-for="(item, index) in content.nav" :key="index" :class="{'products-category__nav-item--active': index == content.nav.length-1}")
          router-link.products-category__nav-item-button(:to="item.link") {{ item.name }}
      .products-category__container
        .products-category__slider(v-if="content.slider")
          .swiper-container
            .swiper-wrapper
              .swiper-slide.products-category__swiper-slide(v-for="image in content.slider" :key="image.id" :data-id="image.id")
                .products-category__slider-item
                  .products-category__slider-item-layout
                  .products-category__slider-item-img-wr(:data-src="image.path")
                    img.products-category__slider-item-img.products-category__slider-item-img--hide(:src='image.path' :alt="image.name")
          .products-category__slider-navigation
            .swiper-button-prev
              SvgIcon(class='products-category__slider-ico' name="arrow" width="22" height="34")
            .products-category__slider-pagination.swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets
            .swiper-button-next
              SvgIcon(class='products-category__slider-ico' name="arrow" width="22" height="34")
        .products-category__block(v-if="content.productList")
          ul.products-category__list.products-category__list--dinamic
            li.products-category__list-item.products-category__list-item--dinamic(v-for="item in content.productList" :data-id="item.id" v-on:mouseover="showImage($event)")
              router-link(:to="item.link") {{ item.name }}
    Footer
</template>

<script>
  import EventBus from '@/eventBus';

  import '@/components/productsCategory/productsCategory.styl';
  import SvgIcon from '@/components/common/svgIcon/SvgIcon.vue';
  import {AjaxHelpersVue} from '@/mixins/AjaxHelpersVue';
  import {SliderHelpersVue} from "@/mixins/SliderHelpersVue";
  import {ScrollbarHelpers} from "@/mixins/ScrollbarHelpers";
  import {ImagePreloaderVue} from '@/mixins/ImagePreloaderVue';
  import Footer from '@/components/common/footer/Footer';

  export default {
    name: 'ProductsCategory',
    mixins: [AjaxHelpersVue, SliderHelpersVue, ScrollbarHelpers, ImagePreloaderVue],

    data() {
      return {
        content: null
      }
    },

    components: {
      SvgIcon,
      Footer
    },

    created() {
      // Загрузка данных для страницы
      EventBus.$on('loadProductsCategory', params => {
        this.request(params.url);
      });
    },

    methods: {
      request(url) {
        this.req('ProductsPage:getProductsCategory', {url}, res => {
          this.content = res.content;
          this.$nextTick(() => {
            this.checkOverflow(url);
            this.initSlider('.products-category__slider', 'products-category__slider', '.products-category__list-item--dinamic', 'products-category__list-item--dinamic--active');
            this.imgPreloader('products-category__slider-item-img-wr', 'products-category__slider-item-img', '', 250);
          })
        }, 'products-category');
      },

      showImage(event) {
        let elem = event.target;
        let tag  = elem.nodeName;
        let id, index;
        if(tag === 'A') {
          id = $(elem).parent().attr('data-id');
        }
        if(tag === 'LI') {
          id = $(elem).attr('data-id');
        }
        index = $('.products-category__slider').find('.products-category__swiper-slide[data-id="' + id + '"]').index();
        this.swiper.slideTo(index, 300);
        $('.products-category__list-item--dinamic').removeClass('products-category__list-item--dinamic--active');
        $($('.products-category__list-item--dinamic')[index]).addClass('products-category__list-item--dinamic--active');
      },

    }
  }
</script>

<template lang="pug">
  .select(:data-select-top-item-id="selectedId", :data-select-top-item-value="selectedValue")
    .select__block-item-title-wr(v-if="data.title")
      .select__block-item-title
        | {{data.title}}
      .select__block-item-question-wr(v-if="data.question")
        .select__block-item-question-popup
          | {{data.question}}
        .select__block-item-question
          | ?
    .select__block-item-select-wr(:class="{'select__block-item-select-wr--hover': selectHover}")
      .select__block-item-select-top(v-on:click="openSelect()")
        .select__block-item-select-top-title
          | {{this.selectedTxt}}
        SvgIcon.select__block-item-select-top-title-ico(name="arrow-left" width="15" height="15")
      .select__block-item-select-bottom
        .select__block-item-select-bottom-items(v-if="data.select")
          .select__block-item-select-bottom-item(v-for="select in data.select", :key="select.id", v-on:click="setCurrentSelect($event, select.id, select.value, select.title)")
            | {{select.title}}
</template>

<script>
  import '@/components/common/select/select.styl';

  import EventBus from '@/eventBus';
  import SvgIcon from '@/components/common/svgIcon/SvgIcon.vue';

  export default {
    name: 'Select',

    props: ['data'],

    components: {
      SvgIcon
    },

    data() {
      return {
        selectedId: '',
        selectedValue: '',
        selectedTxt: '',

        selectHover: false
      }
    },

    created() {
      EventBus.$on('resetSelect', () => {
        this.setDefault();
      });

      EventBus.$on('selectListClose', () => {
        this.selectHover = false;
      });

      this.setDefault();
    },

    methods: {
      openSelect() {
        this.selectHover = !this.selectHover;
      },

      setDefault() {
        this.selectedId    = this.data.select[0].id || '';
        this.selectedValue = this.data.select[0].value || '';
        this.selectedTxt   = this.data.select[0].title || '';
      },

      setCurrentSelect(e, idSelect, valueSelect, txtSelect) {
        this.selectedId = idSelect;
        this.selectedValue = valueSelect;
        this.selectedTxt = txtSelect;

        this.selectHover = false;
      }
    }
  }
</script>

<template lang="pug">
  .conf-type(v-if="content")
    .page__wr
      .conf-type__content
        h1.conf-type__title
          | {{h1}}
        .conf-type__blocks(v-if="content.blocks")
          .conf-type__block(v-for="(block, blockIndex) in content.blocks", :key="block.id")
            .conf-type__block-title
              | {{block.title}}
            .conf-type__block-items(v-if="block.items")
              .conf-type__block-item(v-if="(blockIndex === 0) && i < (block.items.length - 2)", v-for="(item, i) in block.items", :key="item.id")
                .conf-type__block-item-title-wr
                  .conf-type__block-item-title
                    | {{item.title}}
                  .conf-type__block-item-question-wr(v-if="item.question")
                    .conf-type__block-item-question-popup
                      | {{item.question}}
                    .conf-type__block-item-question
                      | ?
                .conf-type__block-item-select-wr(v-if="item.select")
                  .conf-type__block-item-select-top(v-on:click="openSelect($event)")
                    .conf-type__block-item-select-top-title(:data-select-top-item-id-default="item.select[0].id", :data-select-top-item-value-default="item.select[0].value", :data-select-top-item-id="item.select[0].id", :data-select-top-item-value="item.select[0].value")
                      | {{item.select[0].title}}
                    SvgIcon.conf-type__block-item-select-top-title-ico(name="arrow-left" width="15" height="15")
                  .conf-type__block-item-select-bottom
                    .conf-type__block-item-select-bottom-items
                      .conf-type__block-item-select-bottom-item(v-for="select in item.select", :key="select.id", v-on:click="setCurrentSelect($event, select.id, select.value, select.title)")
                        | {{select.title}}

              .conf-type__block-item(v-if="blockIndex > 0", v-for="item in block.items", :key="item.id")
                .conf-type__block-item-title-wr
                  .conf-type__block-item-title
                    | {{item.title}}
                  .conf-type__block-item-question-wr(v-if="item.question")
                    .conf-type__block-item-question-popup
                      | {{item.question}}
                    .conf-type__block-item-question
                      | ?
                .conf-type__block-item-select-wr(v-if="item.select")
                  .conf-type__block-item-select-top(v-on:click="openSelect($event)")
                    .conf-type__block-item-select-top-title(:data-select-top-item-id-default="item.select[0].id", :data-select-top-item-value-default="item.select[0].value", :data-select-top-item-id="item.select[0].id", :data-select-top-item-value="item.select[0].value")
                      | {{item.select[0].title}}
                    SvgIcon.conf-type__block-item-select-top-title-ico(name="arrow-left" width="17" height="17")
                  .conf-type__block-item-select-bottom
                    .conf-type__block-item-select-bottom-items
                      .conf-type__block-item-select-bottom-item(v-for="select in item.select", :key="select.id", v-on:click="setCurrentSelect($event, select.id, select.value, select.title)")
                        | {{select.title}}

              .conf-type__block-item2
                .conf-type__block-subitem(v-if="(blockIndex === 0) && j >= (block.items.length - 2)", v-for="(item, j) in block.items", :key="item.id")
                  .conf-type__block-item-title-wr
                    .conf-type__block-item-title
                      | {{item.title}}
                    .conf-type__block-item-question-wr(v-if="item.question")
                      .conf-type__block-item-question-popup
                        | {{item.question}}
                      .conf-type__block-item-question
                        | ?
                  .conf-type__block-item-select-wr(v-if="item.select")
                    .conf-type__block-item-select-top(v-on:click="openSelect($event)")
                      .conf-type__block-item-select-top-title(:data-select-top-item-id-default="item.select[0].id", :data-select-top-item-value-default="item.select[0].value", :data-select-top-item-id="item.select[0].id", :data-select-top-item-value="item.select[0].value")
                        | {{item.select[0].title}}
                      SvgIcon.conf-type__block-item-select-top-title-ico(name="arrow-left" width="17" height="17")
                    .conf-type__block-item-select-bottom
                      .conf-type__block-item-select-bottom-items
                        .conf-type__block-item-select-bottom-item(v-for="select in item.select", :key="select.id", v-on:click="setCurrentSelect($event, select.id, select.value, select.title)")
                          | {{select.title}}

        .conf-type__btns-wr
          button.conf-type__btn-reset(type="button", v-on:click="reset()")
            SvgIcon.conf-type__btn-reset-ico(name="arrow-circle" width="24" height="24")
            .conf-type__btn-reset-title
              | Сбросить настройки
          button.conf-type__btn-apply(type="button", v-on:click="sendData()")
            .conf-type__btn-apply-title
              | Применить выбор
          .conf-type__btn-preloader
            amazingSpinner

    Footer
</template>

<script>
  import EventBus from '@/eventBus';
  import Footer from '@/components/common/footer/Footer.vue';
  import amazingSpinner from '@/components/common/amazingSpinner/amazingSpinner.vue';
  import SvgIcon from '@/components/common/svgIcon/SvgIcon.vue';

  import '@/components/configuratorType/configuratorType.styl';
  import {AjaxHelpersVue} from '@/mixins/AjaxHelpersVue';

  export default {
    name: 'ConfiguratorType',

    mixins: [AjaxHelpersVue],

    data() {
      return {
        content: null,
        nextPageLink: '',
        pageUrl: '',
      }
    },

    components: {
      SvgIcon,
      Footer,
      amazingSpinner
    },

    created() {
      // Загрузка данных для страницы
      EventBus.$on('loadConfiguratorTypePage', params => {
        this.pageUrl = params.url;
        this.request(params.url);
      });

      EventBus.$on('selectConfClose', () => {
        $('.conf-type__block-item-select-wr--hover')
          .removeClass('conf-type__block-item-select-wr--hover');
      });
    },

    methods: {
      reset() {
        let $select = $('.conf-type__block-item-select-top-title');

        $select.each((i, el) => {
          let idDefault    = $(el).attr('data-select-top-item-id-default') || '';
          let valueDefault = $(el).attr('data-select-top-item-value-default') || '';
          let txtDefault = $(el)
            .parents('.conf-type__block-item-select-wr')
            .find('.conf-type__block-item-select-bottom-item')
            .eq(0)
            .text() || '';

          $(el)
            .attr('data-select-top-item-value', valueDefault)
            .attr('data-select-top-item-id', idDefault)
            .text(txtDefault);
        });
      },

      sendData() {
        let $select = $('.conf-type__block-item-select-top-title');
        let data = [];

        $('.conf-type__btn-preloader').addClass('conf-type__btn-preloader--show');

        $select.each((i, el) => {
          let id = $(el).attr('data-select-top-item-id') || '';
          let value = $(el).attr('data-select-top-item-value') || '';

          data[id] = value;
        });

        this.requestLocal(data);
      },

      openSelect(e) {
        var $parent = $(e.target).parents('.conf-type__block-item-select-wr');

        $parent.toggleClass('conf-type__block-item-select-wr--hover');
      },

      hoverSelect(e, action) {
        //if(action === 'enter') {
        //  $(e.target).addClass('conf-type__block-item-select-wr--hover');
        //}
        //else {
        //  $(e.target).removeClass('conf-type__block-item-select-wr--hover');
        //}
      },

      setCurrentSelect(e, idSelect, valueSelect, textSelect) {
        let $parent = $(e.target).parents('.conf-type__block-item-select-wr');

        $parent
          .find('.conf-type__block-item-select-top-title')
          .attr('data-select-top-item-value', valueSelect)
          .attr('data-select-top-item-id', idSelect)
          .text(textSelect);

        $parent.removeClass('conf-type__block-item-select-wr--hover');
      },

      requestLocal(data) {
        data.url = this.pageUrl;

        this.req('ConfiguratorPage:setConfiguratorTypeData', data, res => {
          if(this.checkProperty(res.content)) {
            this.nextPageLink = res.content.nextPage.lnk;
          }

          this.$nextTick(() => {
            setTimeout(() => {
              $('.conf-type__btn-preloader').removeClass('conf-type__btn-preloader--show');

              setTimeout(() => {
                if(this.nextPageLink) {
                  this.$router.push({path: this.nextPageLink});
                }
              }, 250);
            }, 500);
          });
        }, '', 'localPageRequest');
      },

      request(url) {
        this.req('ConfiguratorPage:getConfiguratorTypePage', {url}, res => {

          if(this.checkProperty(res.content)) {
            this.content = res.content;
            this.nextPageLink = res.content.nextPage.lnk;

            this.$nextTick(() => {

              this.checkOverflow(url);
            })
          }
        }, 'conf-type');
      }
    }
  }
</script>

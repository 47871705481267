import {AjaxHelpersVue} from '@/mixins/AjaxHelpersVue';
import EventBus from '@/eventBus';

export const PopupHelpersVue = {
  mixins: [AjaxHelpersVue],

  methods: {
    requestPopup(api, params) {
      this.req(api, params, res => {
        //EventBus.$emit('popupDataLoaded', res);
      }, 'popup');
    },

    popup(typePopup, action, res) {
      let className = '';

      if(typePopup === 'popupPhone') {
        className = 'popup-call';
      }
      if(typePopup === 'popupOrder') {
        className = 'popup-order';
      }
      if(typePopup === 'popupMessage') {
        className = 'popup-message';
      }

      this.appClasses(action);

      this.popupShowHide(className, action);
    },

    popupShowHide(className, action) {
      let $popup = $(`.${className}`);

      if(action === 'show') {
        $popup.addClass(`${className}--show`);

        setTimeout(() => {
          $popup.addClass(`${className}--transition`);
        }, 250);
      }
      else {
        $popup.removeClass(`${className}--show`);

        setTimeout(() => {
          $popup.removeClass(`${className}--transition`);
        }, 500);
      }
    },

    /*popupPhone(action) {
      let $popupCallPhone = $('.js-popup-call');

      if(action === 'show') {
        $popupCallPhone.addClass('popup-call--show');

        setTimeout(() => {
          $popupCallPhone.addClass('popup-call--transition');
        }, 250);

        //this.requestPopup('Action:getAction', {id});
      }
      else {
        $popupCallPhone.removeClass('popup-call--show');

        setTimeout(() => {
          $popupCallPhone.removeClass('popup-call--transition');
        }, 500);
      }
    },

    popupOrder(action) {
      let $popupOrder = $('.js-popup-order');

      if(action === 'show') {
        $popupOrder.addClass('popup-order--show');

        //this.requestPopup('Action:getAction', {id});
      }
      else {
        $popupOrder.removeClass('popup-order--show');
      }
    },

    popupMessage(action) {
      let $popupMessage = $('.js-popup-message');

      if(action === 'show') {
        $popupMessage.addClass('popup-message--show');
      }
      else {
        $popupMessage.removeClass('popup-message--show');
      }
    },*/

    appClasses(action) {
      if(action === 'show') {
        $('body').addClass('popup popup--effect');
        $('#app').addClass('compensate-scrollbar');

        this.setCompensateScrollbarStyles();
      }
      else {
        $('body').removeClass('popup--effect');

        setTimeout(() => {
          $('body').removeClass('popup');
          $('#app').addClass('compensate-scrollbar');
          $('#compensate').remove();
        }, 500); // время анимации скрытия попапа
      }
    },

    /**
     * Измерить ширину скроллбара
     * Метод взят из модалки бутстрапа
     */
    measureScrollbar() {
      let scrollDiv = document.createElement('div'),
          $body     = $('body');

      scrollDiv.className = 'modal-scrollbar-measure';

      $(scrollDiv).css({
        position: 'absolute',
        top: '-9999px',
        width: '50px',
        height: '50px',
        overflow: 'scroll'
      });

      $body.append(scrollDiv);

      let scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;

      $body[0].removeChild(scrollDiv);

      return scrollbarWidth;
    },

    setCompensateScrollbarStyles() {
      let scrollbarWidth = this.measureScrollbar();

      if(!$('#compensate').length) {
        $('head')
          .append(`<style id="compensate">
                      .compensate-scrollbar{padding-right: ${scrollbarWidth}px;}
                      .page__header{width: calc(100%) !important;}
                      </style>`);
      }

      // .page__header{width: calc(100% - ${scrollbarWidth}px);}
      // Для фиксированного блока
    }
  }
};

<template lang="pug">
  nav.menu-top(v-if="items")
    .swiper-container
      .swiper-wrapper
        .swiper-slide(v-for='item in items', :key="item.id")
          router-link.menu-top__item(:to="item.link")
            .menu-top__item-text {{item.title}}
            .menu-top__item-line
            .menu-top__item-circle
    .swiper-button-next
      SvgIcon(name="arrow", width="11", height="17")
    .swiper-button-prev
      SvgIcon(name="arrow", width="11", height="17")
</template>

<script>
  import '@/components/common/menuTop/menuTop.styl';
  import SvgIcon from '@/components/common/svgIcon/SvgIcon.vue';

  export default {
    name: 'MenuTop',

    props: {
      items: {
        type: Array,
        required: true,
        default: null
      }/*,

      path: {
        type: String
      }*/
    },

    components: {
      SvgIcon
    },

    /*watch: {
      path(newVal, oldVal) {
        this.path = newVal;

        this.$nextTick(() => {
          this.setActiveClass();
        })
      }
    },*/

    methods: {
      initMenuSlider() {
        var $menuTop = $('.menu-top');
        var swiper = new Swiper($menuTop.find('.swiper-container'), {
          slidesPerView: 'auto',
          spaceBetween: 15,
          navigation: {
            nextEl: $menuTop.find('.swiper-button-next'),
            prevEl: $menuTop.find('.swiper-button-prev'),
          },
          breakpointsInverse: true,
          breakpoints: {
            1200: {
              spaceBetween: 15,
            },
            1600: {
              spaceBetween: 25,
            },
          },
        });
      }
      /*setActiveClass() {
        let $menuItems = $('.menu-top__items');
        let words      = [];

        if(this.path.length) {
          $menuItems.find('.menu-top__item--active').removeClass('menu-top__item--active');

          if(this.path !== '/') {
            words = this.path.substring(1).split('/');

            $menuItems.find(`[href*='${words[0]}']`).addClass('menu-top__item--active');
          }
          // Для главной /
          else {
            $menuItems.find(`[href='${this.path}']`).addClass('menu-top__item--active');
          }
        }
      }*/
    },

    mounted() {
      this.$nextTick(() => {
        this.initMenuSlider();
        //this.setActiveClass();
      })
    }
  }
</script>

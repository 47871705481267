<template lang="pug">
  .page__items
    .page__item(data-id="main")
      Main
    .page__item(data-id="about")
      About
    .page__item(data-id="history")
      History
    .page__item(data-id="strategy")
      Strategy
    .page__item(data-id="philosophy")
      Philosophy
    .page__item(data-id="products")
      Products
    .page__item(data-id="products-category")
      ProductsCategory
    .page__item(data-id="products-item")
      ProductsItem
    .page__item(data-id="facilities")
      Facilities
    .page__item(data-id="facilities-item")
      FacilitiesItem
    .page__item(data-id="partners")
      Partners
    .page__item(data-id="dealers")
      Dealers
    .page__item(data-id="sertificates")
      Sertificates
    .page__item(data-id="contacts")
      Contacts
    .page__item(data-id="privacy-policy")
      PrivacyPolicy
    .page__item(data-id="conf")
      Configurator
    .page__item(data-id="conf-type")
      ConfiguratorType
    .page__item(data-id="conf-page")
      ConfiguratorPage
    .page__item(data-id="conf-page-noresult")
      ConfiguratorPageNoResult
    .page__item(data-id="conf-page-list-pump")
      ConfiguratorListPump
    .page__item(data-id="conf-page-list-fire")
      ConfiguratorListFire
    .page__item(data-id="page-404")
      Page404

</template>

<script>
  import EventBus from '@/eventBus';

  import Main from '@/views/Main';
  import About from '@/views/About';
  import History from '@/views/History';
  import Strategy from '@/views/Strategy';
  import Philosophy from '@/views/Philosophy';
  import Products from '@/views/Products';
  import ProductsCategory from '@/views/ProductsCategory';
  import ProductsItem from '@/views/ProductsItem';
  import Facilities from '@/views/Facilities';
  import FacilitiesItem from '@/views/FacilitiesItem';
  import Partners from '@/views/Partners';
  import Dealers from '@/views/Dealers';
  import Sertificates from '@/views/Sertificates';
  import Contacts from '@/views/Contacts';
  import PrivacyPolicy from '@/views/PrivacyPolicy';
  import Configurator from '@/views/Configurator';
  import ConfiguratorType from '@/views/ConfiguratorType';
  import ConfiguratorPage from '@/views/ConfiguratorPage';
  import ConfiguratorPageNoResult from '@/views/ConfiguratorPageNoResult';
  import ConfiguratorListPump from '@/views/ConfiguratorListPump';
  import ConfiguratorListFire from '@/views/ConfiguratorListFire';
  import Page404 from '@/views/Page404';

  export default {
    components: {
      Main,
      About,
      History,
      Strategy,
      Philosophy,
      Products,
      ProductsCategory,
      ProductsItem,
      PrivacyPolicy,
      Facilities,
      FacilitiesItem,
      Partners,
      Dealers,
      Sertificates,
      Contacts,
      Configurator,
      ConfiguratorType,
      ConfiguratorPage,
      ConfiguratorPageNoResult,
      ConfiguratorListPump,
      ConfiguratorListFire,
      Page404
    },

    // Первый заход на страницу & для роутов типа /catalog/:id
    beforeRouteEnter(to, from, next) {
      next(vm => {
        // экземпляр компонента доступен как `vm`

        let params = vm.getParams(to);

        EventBus.$emit(params.event, params);
      })
    },

    // Навигация по ссылкам
    beforeRouteUpdate(to, from, next) {
      let params = this.getParams(to);

      EventBus.$emit(params.event, params);

      next();
    },

    methods: {
      getParams(route) {
        let path = route.path;
        let url  = route.path || '';

        let routeName = route.name || '';

        let event  = '';
        let params = {};

        if(path === '/') {
          event = 'loadMainPage';
        }
        else if(path === '/o-kompanii') {
          event = 'loadAboutPage';
        }
        else if(path === '/o-kompanii/istoriya') {
          event = 'loadHistoryPage';
        }
        else if(path === '/o-kompanii/strategiya') {
          event = 'loadStrategyPage';
        }
        else if(path === '/o-kompanii/filosofiya') {
          event = 'loadPhilosophyPage';
        }
        else if(path === '/produkciya') {
          event = 'loadProductsPage';
        }
        else if(path === '/vipolnennye-obekty') {
          event = 'loadFacilitiesPage';
        }
        else if(path === '/partnyory') {
          event = 'loadPartnersPage';
        }
        else if(path === '/dilery') {
          event = 'loadDealersPage';
        }
        else if(path === '/sertifikaty') {
          event = 'loadSertificatesPage';
        }
        else if(path === '/kontakty') {
          event = 'loadContactsPage';
        }
        else if(path === '/politika-konfidencialnosti') {
          event = 'loadPolicyPage';
        }
        else {
          event = 'load404Page';
        }

        // Name

        if(routeName === 'ProductsCategory') {
          event         = 'loadProductsCategory';
          params.params = route.params || {};
        }

        if(routeName === 'ProductsItem') {
          event         = 'loadProductsItem';
          params.params = route.params || {};
        }

        if(routeName === 'FacilitiesCategory') {
          event         = 'loadFacilitiesPage';
          params.params = route.params || {};
        }

        if(routeName === 'FacilitiesItem') {
          event         = 'loadFacilitiesItem';
          params.params = route.params || {};
        }

        if(routeName === 'Configurator') {
          event         = 'loadConfiguratorPage';
          params.params = route.params || {};
        }

        if(routeName === 'ConfiguratorType') {
          event         = 'loadConfiguratorTypePage';
          params.params = route.params || {};
        }

        if(routeName === 'ConfiguratorPage') {
          event         = 'loadConfiguratorPageSingle';
          params.params = route.params || {};
        }

        if(routeName === 'ConfiguratorPageNoResult') {
          event         = 'loadConfiguratorPageNoFound';
          params.params = route.params || {};
        }

        if(routeName === 'ConfiguratorPageListPump') {
          event         = 'loadConfiguratorPageListPump';
          params.params = route.params || {};
        }

        if(routeName === 'ConfiguratorPageListFire') {
          event         = 'loadConfiguratorPageListFire';
          params.params = route.params || {};
        }

        params.event = event || '';
        params.url   = url;

        return params;
      }
    }
  }
</script>

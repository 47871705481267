<template lang="pug">
  .conf-page-list(v-if="content")
    .page__wr
      .conf-page-list__content
        h1.conf-page-list__title.conf-page-list__title--font
          | {{h1}}
      .conf-page-list__blocks
        .conf-page-list__block
          .conf-page-list__block-title
            | 1. Организация, контактное лицо ответственного за заказ:
          .conf-page-list__block-content
            .conf-page-list__block-input-row
              .conf-page-list__block-input-wr
                .conf-page-list__block-input-title
                  | Организация:
                input.conf-page-list__block-input(type="text" v-model="organization")
            .conf-page-list__block-input-row
              .conf-page-list__block-input-wr
                .conf-page-list__block-input-title
                  | Адрес:
                input.conf-page-list__block-input(type="text" v-model="address")
            .conf-page-list__block-input-row
              .conf-page-list__block-input-wr
                .conf-page-list__block-input-title
                  | ФИО:
                input.conf-page-list__block-input(type="text" v-model="fio")
            .conf-page-list__block-input-row
              .conf-page-list__block-input-wr
                .conf-page-list__block-input-title
                  | Должность:
                input.conf-page-list__block-input(type="text" v-model="position")
            .conf-page-list__block-input-row
              .conf-page-list__block-input-wr
                .conf-page-list__block-input-title
                  | Телефон:
                input.conf-page-list__block-input(type="tel" v-model="phone")
            .conf-page-list__block-input-row
              .conf-page-list__block-input-wr
                .conf-page-list__block-input-title
                  | Email:
                input.conf-page-list__block-input(type="email" v-model="email")
            .conf-page-list__block-input-row
              .conf-page-list__block-input-wr
                .conf-page-list__block-input-title
                  | Объект:
                input.conf-page-list__block-input(type="text" v-model="object")

        .conf-page-list__block
          .conf-page-list__block-title
            | 2. Кол-во и электрические параметры подключаемых двигателей
          .conf-page-list__block-content
            .conf-page-list__block-input-row
              .conf-page-list__block-input-wr
                .conf-page-list__block-input-title
                  | Тип насосного агрегата:
                input.conf-page-list__block-input(type="text" v-model="nasosAgregat")
            .conf-page-list__block-input-row
              .conf-page-list__block-input-wr
                .conf-page-list__block-input-title
                  | Количество рабочих насосных агрегатов:
                input.conf-page-list__block-input(type="text" v-model="agregatyRabochyi")
              .conf-page-list__block-input-wr
                .conf-page-list__block-input-title
                  | Количество резервных насосных агрегатов:
                input.conf-page-list__block-input(type="text" v-model="agregatyRezerv")
            .conf-page-list__block-input-row
              .conf-page-list__block-input-wr
                .conf-page-list__block-input-title
                  | Мощность двигателя, кВт:
                input.conf-page-list__block-input(type="text" v-model="moschnost")
              .conf-page-list__block-input-wr
                .conf-page-list__block-input-title
                  | Ток двигателя, А (АС-3):
                input.conf-page-list__block-input(type="text" v-model="tokDvigatelya")
          .conf-page-list__block-content-select-wr(v-if="content.section2.items")
            .conf-page-list__block-content-select-item.conf-page-list__block-content-select-item--fire(v-for="item in content.section2.items", :key="item.id")
              Select(:data="item")

        .conf-page-list__block.conf-page-list__block--section3-fire
          .conf-page-list__block-title
            | 3. Вспомогательное оборудование
          .conf-page-list__block-content
            .conf-page-list__block-input-row
              .conf-page-list__block-input-wr
                .conf-page-list__block-input-title
                  | Жокей насос:
                input.conf-page-list__block-input(type="text" v-model="nasosJokey")
            .conf-page-list__block-input-row
              .conf-page-list__block-input-wr
                .conf-page-list__block-input-title
                  | Количество насосов:
                input.conf-page-list__block-input(type="text" v-model="nasosJokeyKolychestvo")
              .conf-page-list__block-input-wr
                .conf-page-list__block-input-title
                  | Напряжение питания, В:
                input.conf-page-list__block-input(type="text" v-model="nasosJokeyNapryajenie")
            .conf-page-list__block-input-row
              .conf-page-list__block-input-wr
                .conf-page-list__block-input-title
                  | Мощность двигателя (кВт):
                input.conf-page-list__block-input(type="text" v-model="nasosJokeyMoschnost")
              .conf-page-list__block-input-wr
                .conf-page-list__block-input-title
                  | Ток двигателя, А (АС-3):
                input.conf-page-list__block-input(type="text" v-model="nasosJokeyTokDvigatelya")
          .conf-page-list__block-content
            .conf-page-list__block-input-row
              .conf-page-list__block-input-wr
                .conf-page-list__block-input-title
                  | Дренажный насос:
                input.conf-page-list__block-input(type="text" v-model="nasosDrenaj")
            .conf-page-list__block-input-row
              .conf-page-list__block-input-wr
                .conf-page-list__block-input-title
                  | Количество насосов:
                input.conf-page-list__block-input(type="text" v-model="nasosDrenajKolychestvo")
              .conf-page-list__block-input-wr
                .conf-page-list__block-input-title
                  | Напряжение питания, В:
                input.conf-page-list__block-input(type="text" v-model="nasosDrenajNapryajenie")
            .conf-page-list__block-input-row
              .conf-page-list__block-input-wr
                .conf-page-list__block-input-title
                  | Мощность двигателя (кВт):
                input.conf-page-list__block-input(type="text" v-model="nasosDrenajMoschnost")
              .conf-page-list__block-input-wr
                .conf-page-list__block-input-title
                  | Ток двигателя, А (АС-3):
                input.conf-page-list__block-input(type="text" v-model="nasosDrenajTokDvigatelya")
          .conf-page-list__block-content
            .conf-page-list__block-input-row
              .conf-page-list__block-input-wr
                .conf-page-list__block-input-title
                  | Задвижка:
                input.conf-page-list__block-input(type="text" v-model="zadvijka")
            .conf-page-list__block-input-row
              .conf-page-list__block-input-wr
                .conf-page-list__block-input-title
                  | Количество насосов:
                input.conf-page-list__block-input(type="text" v-model="zadvijkaKolychestvo")
              .conf-page-list__block-input-wr
                .conf-page-list__block-input-title
                  | Напряжение питания, В:
                input.conf-page-list__block-input(type="text" v-model="zadvijkaNapryajenie")
            .conf-page-list__block-input-row
              .conf-page-list__block-input-wr
                .conf-page-list__block-input-title
                  | Мощность двигателя (кВт):
                input.conf-page-list__block-input(type="text" v-model="zadvijkaMoschnost")
              .conf-page-list__block-input-wr
                .conf-page-list__block-input-title
                  | Ток двигателя, А (АС-3):
                input.conf-page-list__block-input(type="text" v-model="zadvijkaTokDvigatelya")

        .conf-page-list__block(v-if="content.section4")
          .conf-page-list__block-title
            | 4. Доступные опции
          .conf-page-list__block-content
            .conf-page-list__block-item-title-wr(v-if="content.section4.subtitle")
              .conf-page-list__block-item-title
                | {{content.section4.subtitle}}
              .conf-page-list__block-item-question-wr(v-if="content.section4.question")
                .conf-page-list__block-item-question-popup
                  | {{content.section4.question}}
                .conf-page-list__block-item-question
                  | ?
            .conf-page-list__block-chk-items(v-if="content.section4.items")
              .conf-page-list__block-chk-item(v-for="item in content.section4.items", :key="item.id")
                Chk(:data="item")

        .conf-page-list__block
          .conf-page-list__block-title
            | 5. Необходимые комментарии заказчика
          .conf-page-list__block-content
            textarea.conf-page-list__block-textarea(v-model="comment")

        .conf-page-list__btns-wr
          .conf-page-list__btns-wr-left
            button.conf-page-list__btn-reset(type="button", v-on:click="reset()")
              SvgIcon.conf-page-list__btn-reset-ico(name="close" width="22" height="22")
              .conf-page-list__btn-reset-title
                | Очистить форму
            button.conf-page-list__btn-apply(type="button", v-on:click="sendData()")
              .conf-page-list__btn-apply-title
                | Отправить форму
          .conf-page-list__btns-wr-right
            .conf-page-list__btn-preloader
              amazingSpinner
            .conf-page-list__btn-message
              | Данные успешно отправлены!
    Footer
</template>

<script>
  import EventBus from '@/eventBus';
  import Footer from '@/components/common/footer/Footer.vue';
  import amazingSpinner from '@/components/common/amazingSpinner/amazingSpinner.vue';
  import SvgIcon from '@/components/common/svgIcon/SvgIcon.vue';
  import Select from '@/components/common/select/select';
  import Chk from '@/components/common/chk/chk';

  import '@/components/configuratorList/configuratorList.styl';
  import {AjaxHelpersVue} from '@/mixins/AjaxHelpersVue';

  export default {
    name: 'ConfiguratorListFire',

    mixins: [AjaxHelpersVue],

    data() {
      return {
        content: null,

        // Section 1
        organization: '',
        address: '',
        fio: '',
        position: '',
        phone: '',
        email: '',
        object: '',

        // Section 2
        nasosAgregat: '',
        agregatyRabochyi: '',
        agregatyRezerv: '',
        moschnost: '',
        tokDvigatelya: '',

        // Section 3
        nasosJokey: '',
        nasosJokeyKolychestvo: '',
        nasosJokeyNapryajenie: '',
        nasosJokeyMoschnost: '',
        nasosJokeyTokDvigatelya: '',

        nasosDrenaj: '',
        nasosDrenajKolychestvo: '',
        nasosDrenajNapryajenie: '',
        nasosDrenajMoschnost: '',
        nasosDrenajTokDvigatelya: '',

        zadvijka: '',
        zadvijkaKolychestvo: '',
        zadvijkaNapryajenie: '',
        zadvijkaMoschnost: '',
        zadvijkaTokDvigatelya: '',

        // Section 5
        comment: ''
      }
    },

    components: {
      SvgIcon,
      Footer,
      Select,
      Chk,
      amazingSpinner
    },

    created() {
      // Загрузка данных для страницы
      EventBus.$on('loadConfiguratorPageListFire', params => {
        this.request(params.url);
      });
    },

    methods: {
      reset() {
        // Section 1
        this.organization = '';
        this.address      = '';
        this.fio          = '';
        this.position     = '';
        this.phone        = '';
        this.email        = '';
        this.object       = '';

        // Section 2
        this.nasosAgregat     = '';
        this.agregatyRabochyi = '';
        this.agregatyRezerv   = '';
        this.moschnost        = '';
        this.tokDvigatelya    = '';

        EventBus.$emit('resetSelect');

        // Section 3
        this.nasosJokey = '';
        this.nasosJokeyKolychestvo = '';
        this.nasosJokeyNapryajenie = '';
        this.nasosJokeyMoschnost = '';
        this.nasosJokeyTokDvigatelya = '';

        this.nasosDrenaj = '';
        this.nasosDrenajKolychestvo = '';
        this.nasosDrenajNapryajenie = '';
        this.nasosDrenajMoschnost = '';
        this.nasosDrenajTokDvigatelya = '';

        this.zadvijka = '';
        this.zadvijkaKolychestvo = '';
        this.zadvijkaNapryajenie = '';
        this.zadvijkaMoschnost = '';
        this.zadvijkaTokDvigatelya = '';

        // Section 4
        this.content.section4.items.forEach((el) => {
          el.checked = false;
        });

        // Section 5
        this.comment = '';
      },

      sendData() {
        let data = [];

        // Section 1
        data['organization'] = this.organization;
        data['address']      = this.address;
        data['fio']          = this.fio;
        data['position']     = this.position;
        data['phone']        = this.phone;
        data['email']        = this.email;
        data['object']       = this.object;

        // Section 2
        data['nasosAgregat']     = this.nasosAgregat;
        data['agregatyRabochyi'] = this.agregatyRabochyi;
        data['agregatyRezerv']   = this.agregatyRezerv;
        data['moschnost']        = this.moschnost;
        data['tokDvigatelya']    = this.tokDvigatelya;

        let $select = $('.conf-page-list__block-content-select-item--fire').find('.select');

        $select.each((i, el) => {
          let id    = $(el).attr('data-select-top-item-id') || '';
          let value = $(el).attr('data-select-top-item-value') || '';

          data[id] = value;
        });

        // Section 3
        data['nasosJokey'] = this.nasosJokey;
        data['nasosJokeyKolychestvo']   = this.nasosJokeyKolychestvo;
        data['nasosJokeyNapryajenie']   = this.nasosJokeyNapryajenie;
        data['nasosJokeyMoschnost']     = this.nasosJokeyMoschnost;
        data['nasosJokeyTokDvigatelya'] = this.nasosJokeyTokDvigatelya;

        data['nasosDrenaj']              = this.nasosDrenaj;
        data['nasosDrenajKolychestvo']   = this.nasosDrenajKolychestvo;
        data['nasosDrenajNapryajenie']   = this.nasosDrenajNapryajenie;
        data['nasosDrenajMoschnost']     = this.nasosDrenajMoschnost;
        data['nasosDrenajTokDvigatelya'] = this.nasosDrenajTokDvigatelya;

        data['zadvijka'] = this.zadvijka;
        data['zadvijkaKolychestvo'] = this.zadvijkaKolychestvo;
        data['zadvijkaNapryajenie'] = this.zadvijkaNapryajenie;
        data['zadvijkaMoschnost'] = this.zadvijkaMoschnost;
        data['zadvijkaTokDvigatelya'] = this.zadvijkaTokDvigatelya;

        // Section 4
        this.content.section4.items.forEach((el) => {
          if(el.checked) {
            data[el.id] = true;
          }
        });

        // Section 5
        data['comment'] = this.comment;

        data['url'] = this.$route.path || '';

        this.requestLocal(data);
      },

      requestLocal(data) {
        $('.conf-page-list__btn-preloader').addClass('conf-page-list__btn-preloader--show');
        $('.conf-page-list__btn-message').removeClass('conf-page-list__btn-message--show');

        this.req('ConfiguratorPage:setConfiguratorListData', data, res => {
          this.$nextTick(() => {
            setTimeout(() => {
              $('.conf-page-list__btn-preloader').removeClass('conf-page-list__btn-preloader--show');
              $('.conf-page-list__btn-message').addClass('conf-page-list__btn-message--show');

              setTimeout(() => {
                $('.conf-page-list__btn-message').removeClass('conf-page-list__btn-message--show');
              }, 2500);
            }, 500);
          });
        }, '', 'localPageRequest');
      },

      request(url) {
        this.req('ConfiguratorPage:getConfiguratorPageListFire', {url}, res => {
          this.content = res.content;

          if(this.checkProperty(res.content)) {
            this.$nextTick(() => {

              this.checkOverflow(url);
            })
          }
        }, 'conf-page-list-fire');
      }
    }
  }
</script>

<template lang="pug">
  .contacts(v-if="content")
    .page__wr
      .contacts__wr
        .contacts__map(v-if="content.initMapConfig")
          .contacts__map-preloader
          #contactsMap
            .contacts__map-button(v-on:click="hideMap($event, content.initMapConfig)")
              SvgIcon.contacts__map-button-icon(name="close" width="34" height="34")
          .contacts__map-block
            p.contacts__map-description Нажмите для просмотра карты
            .contacts__map-circle(v-on:click="showMap($event)")
              SvgIcon.contacts__map-circle-icon(name="plus")
        .contacts__block
          h2.contacts__headline(v-if="content.subtitle", v-html="content.subtitle")
          .contacts__scroll
            .contacts__scroll-wr
              .contacts__contacts(v-if="content.addreses")
                .contacts__contact-wr(v-for="(item, index) in content.addreses" :key="index")
                  .contacts__contact
                    SvgIcon.contacts__contact-icon(name="location" width="22" height="29")
                    .contacts__contact-address(v-html="item.name")
                  .contacts__contact(v-if="item.phone.length" v-for="(phone, index) in item.phone" :key="index")
                    SvgIcon.contacts__contact-icon(name="phone" width="18" height="29")
                    a.contacts__contact-link(:href="phone.link") {{ phone.text }}
              .contacts__button.contacts__button--lite(v-if="content.button", @click="openPopup")
                .contacts__button-text {{content.button}}
              .contacts__popup
                form.contacts__popup-form(v-on:submit.prevent="submit" :class="{'contacts__popup-form--show': !formSend}")
                  h2.contacts__popup-form-legend Оставить заявку
                  .contacts__popup-form-block
                    input.contacts__popup-form-input(type="text" placeholder="Ваше имя" v-model="name" required)
                    input.contacts__popup-form-input(type="email" placeholder="E-mail" v-model="email" required)
                  input.contacts__popup-form-input(type="tel" name="phone" v-model="phone" v-mask="'+7(###)-###-##-##'" placeholder="+7(___)-___-__-__" required)
                  textarea.contacts__popup-form-textarea(placeholder="Комментарий" v-model="comment" required)
                  button.contacts__popup-form-button(type="submit")
                    .contacts__popup-form-button-text Отправить
                  .contacts__popup-form-description
                    p.contacts__popup-form-description-text Нажимая кнопку «Отправить», я даю согласие на
                      br
                      router-link(to="/politika-konfidencialnosti" target="_blank").contacts__popup-form-description-text--link  обработку персональных данных
                .contacts__popup-form-send(:class="{'contacts__popup-form-send--show': formSend}")
                  h5.contacts__popup-form-send-text Ваша заявка успешно отправлена!
          Samedia.contacts__samedia
    Footer
</template>

<script>
  import EventBus from '@/eventBus';
  import SvgIcon from '@/components/common/svgIcon/SvgIcon.vue';
  import Samedia from '@/components/common/samedia/Samedia.vue'
  import Footer from '@/components/common/footer/Footer.vue'

  import '@/components/contacts/contacts.styl';
  import {AjaxHelpersVue} from '@/mixins/AjaxHelpersVue';
  import {YaHelpersVue} from '@/mixins/YaHelpersVue';
  import {PopupHelpersVue} from '@/mixins/PopupHelpersVue';
  import {FormHelpersVue} from '@/mixins/FormHelpersVue';
  import {ScrollbarHelpers} from "@/mixins/ScrollbarHelpers";

  export default {
    name: 'Contacts',

    mixins: [AjaxHelpersVue, YaHelpersVue, FormHelpersVue, ScrollbarHelpers],

    data() {
      return {
        isMapInit: false,
        content: null,
        name: '',
        phone: '',
        email: '',
        comment: '',
        formSend: false
      }
    },

    components: {
      SvgIcon,
      Samedia,
      Footer
    },

    created() {
      // Загрузка данных для страницы
      EventBus.$on('loadContactsPage', params => {
        this.request(params.url);
      });
    },

    mounted() {
    },

    methods: {
      request(url) {
        this.req('ContactsPage:getContactsPage', {url}, res => {
          this.content = res.content;
          if(this.checkProperty(res.content.initMapConfig)) {
            this.$nextTick(() => {
              if(!this.isMapInit) {
                this.initMapLocal('#contactsMap', res.content.initMapConfig);
              }
              this.checkOverflow(url);

              if($(window).width() >= 1280) {
                this.customScrollbarInit('.contacts__scroll');
              }
            })
          }
        }, 'contacts');
      },

      initMapLocal(block, initMapConfig) {
        this
          .initMap(block, initMapConfig)
          // Когда карта загружена
          .then(() => {
            // Добавляем на карту placemarks
            this.addAllPlacemarks(initMapConfig.coords).then(() => {
              setTimeout(() => {
                $('.contacts__map-preloader').addClass('contacts__map-preloader--hide');
              }, 750);
            });

            this.isMapInit = true;
          });
      },

      openPopup() {
        $.fancybox.open({
          src: '.contacts__popup',
          type: 'inline',
          opts: {
            //animationEffect: "zoom-in-out",
            beforeShow: function(instance, current) {
              $('.page').addClass('popup popup--effect');
            },
            beforeClose: function(instance, current) {
              $('.page').removeClass('popup popup--effect');
            },
            touch: false,
            buttons: ['close'],
          }
        });
      },

      submit() {
        let data = {
          name: this.name,
          email: this.email,
          phone: this.phone,
          comment: this.comment
        };

        this.submitForm('Common:sendOrderForm', data, res => {
          this.name    = '';
          this.email   = '';
          this.phone   = '';
          this.comment = '';
          if(res.status === 'ok') {
            this.formSend = true;
          }
        });
      },
    }
  }
</script>

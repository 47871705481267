export const ScrollbarHelpers = {
    methods: {
        customScrollbarInit(elem) {
            if($(elem).length) {
                $(elem).mCustomScrollbar({
                    mouseWheel: {
                        enable: true,
                        scrollInertia: 200,
                    },
                    theme: "rounded-dark",
                    scrollEasing: "linear",
                });
            }
        },
    }
}

<template lang="pug">
  .dealers(v-if="content")
    .dealers__left-bg
    .dealers__right-bg
    .page__wr
      .dealers__main
        .dealers__left
          ul.dealers__nav(v-if="content.nav")
            li.dealers__nav-item(v-for="(item, index) in content.nav" :key="index" :class="{'dealers__nav-item--active': index == content.nav.length-1}")
              router-link.dealers__nav-item-button(:to="item.link") {{ item.name }}
          .dealers__form-legend.dealers__form-legend--small.dealers__form-legend--margin-bottom-43 Для получения более подробной информации, оставьте заявку и менеджер с Вами свяжется
          .dealers__form-legend.dealers__form-legend Хотите стать дилером?
          form.dealers__form(v-on:submit.prevent="submit" :class="{'dealers__form--show': !formSend}")
            .dealers__form-legend.dealers__form-legend--gray Оставить заявку
            .dealers__form-legend.dealers__form-legend--small Указать контакты
            input.dealers__form-input(type="tel" name="phone" v-model="phone" v-mask="'+7(###)-###-##-##'" placeholder="+7(____)-____-___-___" required)
            input.dealers__form-input(type="text" placeholder="Ваше имя" v-model="name" required)
            input.dealers__form-input(type="email" placeholder="E-mail" v-model="email" required)
            .dealers__form-description
              p.dealers__form-description-text Нажимая кнопку «Отправить», я даю согласие
              p.dealers__form-description-text на
                router-link(to="/politika-konfidencialnosti" target="_blank").dealers__form-description-text--link  обработку персональных данных
            button.dealers__button(type="submit")
              .dealers__button-text Отправить
          .dealers__form-send(:class="{'dealers__form-send--show': formSend}")
            h5.dealers__form-send-text Ваша заявка#[br]успешно#[br]отправлена!
            SvgIcon(class='dealers__form-send-ico' name="send" width="74" height="65")
        .dealers__right
          .dealers__list(v-if="content.items")
            a.dealers__list-item(v-for="item in content.items" :key="item.id" :href="item.link" target="_blank")
              .dealers__list-item-logo-wr(:data-src="item.image")
                img.dealers__list-item-logo.dealers__list-item-logo--hide(:src="item.image")
                .dealers__list-item-logo-preloader
              .dealers__list-item-title(v-if="item.title") {{item.title}}
    Footer
</template>

<script>
  import EventBus from '@/eventBus';

  import '@/components/dealers/dealers.styl';
  import SvgIcon from '@/components/common/svgIcon/SvgIcon.vue';
  import {AjaxHelpersVue} from '@/mixins/AjaxHelpersVue';
  import {ScrollbarHelpers} from "@/mixins/ScrollbarHelpers";
  import {FormHelpersVue} from "@/mixins/FormHelpersVue";
  import {ImagePreloaderVue} from '@/mixins/ImagePreloaderVue';
  import Footer from '@/components/common/footer/Footer';

  export default {
    name: 'Dealers',
    mixins: [AjaxHelpersVue, ScrollbarHelpers, FormHelpersVue, ImagePreloaderVue],

    data() {
      return {
        content: null,
        name: '',
        phone: '',
        email: '',
        formSend: false
      }
    },

    components: {
      SvgIcon,
      Footer
    },

    created() {
      // Загрузка данных для страницы
      EventBus.$on('loadDealersPage', params => {
        this.request(params.url);
      });
    },

    methods: {
      request(url) {
        this.req('DealersPage:getDealersPage', {url}, res => {
          this.content = res.content;
          this.$nextTick(() => {
            this.checkOverflow(url);
            this.imgPreloader('dealers__list-item-logo-wr', 'dealers__list-item-logo', 'dealers__list-item-logo-preloader', 250);
          })
        }, 'dealers');
      },

      submit() {
        let data = {
          name: this.name,
          email: this.email,
          phone: this.phone
        };

        this.submitForm('DealersPage:sendForm', data, res => {
          this.name    = '';
          this.email   = '';
          this.phone   = '';
          if(res.status === 'ok') {
            this.formSend = true;
          }
        });
      },
    }
  }
</script>

import {AjaxHelpersVue} from '@/mixins/AjaxHelpersVue';
import {PopupHelpersVue} from '@/mixins/PopupHelpersVue';
//import EventBus from '@/eventBus';

export const FormHelpersVue = {
  mixins: [AjaxHelpersVue, PopupHelpersVue],

  methods: {
    submitForm(api, data, callback) {
      //setTimeout(() => {
      //  this.popup('popupMessage', 'show');
      //}, 500);

      this.req(api, data, res => {

        if($.isFunction(callback)) {
          callback(res);
        }

        /*if(this.checkProperty(res.status)) {

          if(res.status === 'ok') {
            if($.isFunction(callback)) {
              callback(res);
            }

            // Если нужно отправить данные в попап, например, сообщение
            EventBus.$emit('popupMessageDataLoaded', res);

            setTimeout(() => {
              this.popup('popupMessage', 'show');
            }, 500);
          }
        }*/
      }, null, 'form');
    },

    openPopup() {
      $.fancybox.open({
        src: '.main__section-popup',
        type: 'inline',
        opts: {
          //animationEffect: "zoom-in-out",
          beforeShow: function(instance, current) {
            $('.page').addClass('popup popup--effect');
          },
          beforeClose: function(instance, current) {
            $('.page').removeClass('popup popup--effect');
          },
          touch: false,
          buttons: ['close'],
        }
      });
    },

    submit() {
      let data = {
        name: this.name,
        email: this.email,
        phone: this.phone,
        comment: this.comment
      };

      this.submitForm('Common:sendOrderForm', data, res => {
        this.name    = '';
        this.email   = '';
        this.phone   = '';
        this.comment = '';
        if(res.status === 'ok') {
          this.formSend = true;
        }
      });
    },
  }
};

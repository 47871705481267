<template lang="pug">
  .facilities(v-if="content")
    .page__wr
      ul.facilities__nav(v-if="content.nav")
        li.facilities__nav-item(v-for="(item, index) in content.nav" :key="index" :class="{'facilities__nav-item--active': index == content.nav.length-1}")
          router-link.facilities__nav-item-button(:to="item.link") {{ item.name }}
      .facilities__main
        .facilities__left
          .facilities__title(v-if="content.title") {{content.title}}
          .facilities__categories(v-if="content.categories")
            router-link.facilities__categories-item(v-for="category in content.categories", :key="category.id", :to="category.link")
              .facilities__categories-item-text(v-if="category.name") {{category.name}}
        .facilities__right
          .facilities__list(v-if="content.items")
            router-link.facilities__item(v-for="item in content.items" :key="item.id" :to="item.link")
              .facilities__item-img-wr(:data-src="item.image")
                img.facilities__item-img.facilities__item-img--hide(:src="item.image")
              .facilities__item-name(v-if="item.name") {{item.name}}
    Footer
</template>

<script>
  import EventBus from '@/eventBus';

  import '@/components/facilities/facilities.styl';
  import SvgIcon from '@/components/common/svgIcon/SvgIcon.vue';
  import {AjaxHelpersVue} from '@/mixins/AjaxHelpersVue';
  import {ScrollbarHelpers} from "@/mixins/ScrollbarHelpers";
  import {ImagePreloaderVue} from '@/mixins/ImagePreloaderVue';
  import Footer from '@/components/common/footer/Footer';

  export default {
    name: 'Facilities',
    mixins: [AjaxHelpersVue, ScrollbarHelpers, ImagePreloaderVue],

    data() {
      return {
        content: null
      }
    },

    components: {
      SvgIcon,
      Footer
    },

    created() {
      // Загрузка данных для страницы
      EventBus.$on('loadFacilitiesPage', params => {
        this.request(params.url);
      });

      // Данные страницы загружены
      EventBus.$on('pageLoaded', res => {
        let path = this.$route.path || '';

        this.$nextTick(() => {
          setTimeout(() => {
            this.setInitialActiveClass(path);
          }, 200);
        });
      });

      this.$nextTick(() => {
        setTimeout(() => {
          this.setActiveClass();
        }, 200);
      });
    },

    methods: {
      request(url) {
        this.req('FacilitiesPage:getFacilitiesPage', {url}, res => {
          this.content = res.content;
          this.$nextTick(() => {
            this.checkOverflow(url);
            this.imgPreloader('facilities__item-img-wr', 'facilities__item-img', '', 250);
          })
        }, 'facilities');
      },

      setInitialActiveClass(path) {
        let pathArr = path.split('/');

        $('.facilities__categories-item').removeClass('facilities__categories-item--active');

        if(pathArr[2]) {
          $(`.facilities__categories-item[href*='${pathArr[2]}']`).addClass('facilities__categories-item--active');
        }
        else {
          $('.facilities__categories-item').eq(0).addClass('facilities__categories-item--active');
        }
      },

      setActiveClass() {
        $(document).on('click', '.facilities__categories-item', function() {
          $('.facilities__categories-item')
            .filter('.facilities__categories-item--active')
            .removeClass('facilities__categories-item--active');

          $(this).addClass('facilities__categories-item--active');
        });
      }
    }
  }
</script>

<template lang="pug">
  .main__section(v-if="section5")
    .main__section-slider.main__section-slider--five(v-if="section5.slider")
      .swiper-container
        .swiper-wrapper
          .swiper-slide.main__section-swiper-slide(v-for="image in section5.slider" :key="image.id" :data-id="image.id")
            .main__section-slider-item
              .main__section-slider-item-layout
              .main__section-slider-item-img-wr(:data-src="image.path")
                img.main__section-slider-item-img.main__section-slider-item-img--hide(:src='image.path' :alt="image.name")
      .main__section-slider-navigation
        .swiper-button-prev
          SvgIcon(class='main__section-slider-ico' name="arrow" width="22" height="34")
        .main__section-slider-pagination.swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets
        .swiper-button-next
          SvgIcon(class='main__section-slider-ico' name="arrow" width="22" height="34")
    .main__section-block
      h2.main__section-headline(v-if="section5.subtitle", v-html="section5.subtitle")
      .main__section-scroll.main__section-scroll--five
        ul.main__section-list.main__section-list--dinamic(v-if="section5.productList")
          li.main__section-list-item.main__section-list-item--dinamic(v-for="item in section5.productList" :data-id="item.id" v-on:mouseover="showImage($event)")
            router-link(:to="item.link") {{ item.name }}

</template>

<script>
  import EventBus from '@/eventBus';
  import SvgIcon from '@/components/common/svgIcon/SvgIcon.vue';

  import {SliderHelpersVue} from "@/mixins/SliderHelpersVue";
  import {ScrollbarHelpers} from "@/mixins/ScrollbarHelpers";
  import {ImagePreloaderVue} from '@/mixins/ImagePreloaderVue';


  export default {
    name: 'SectionFive',

    mixins: [SliderHelpersVue, ScrollbarHelpers, ImagePreloaderVue],

    props: ['section5'],

    data() {
      return {
        content: null
      }
    },

    components: {
      SvgIcon
    },

    created() {
      this.$nextTick(() => {
        this.initSlider('.main__section-slider--five', 'main__section-slider--five', '.main__section-list-item--dinamic', 'main__section-list-item--dinamic--active');
        this.customScrollbarInit('.main__section-scroll--five');
        this.imgPreloader('main__section-slider-item-img-wr', 'main__section-slider-item-img', '', 250);
      });
    },

    methods: {
      showImage(event) {
        if($(window).width() < 1260) {
          return false;
        }

        let elem = event.target;
        let tag  = elem.nodeName;
        let id, index;
        if(tag === 'A') {
          id = $(elem).parent().attr('data-id');
        }
        if(tag === 'LI') {
          id = $(elem).attr('data-id');
        }
        index = $('.main__section-slider').find('.main__section-swiper-slide[data-id="' + id + '"]').index();
        this.swiper.slideTo(index, 300);
        $('.main__section-list-item--dinamic').removeClass('main__section-list-item--dinamic--active');
        $($('.main__section-list-item--dinamic')[index]).addClass('main__section-list-item--dinamic--active');
      },
    },

  }
</script>

<template lang="pug">
  .conf-page(v-if="content")
    .page__wr
      .conf-page__content
        h1.conf-page__title
          | {{h1}}
        .conf-page__content-top
          .conf-page__content-top-info
            .conf-page__content-top-info-left
              .conf-page__content-top-info-left-img-wr
                img.conf-page__content-top-info-left-img(:src="content.img")
            .conf-page__content-top-info-right
              .conf-page__content-top-info-right-items(v-if="content.info")
                .conf-page__content-top-info-right-item(v-for="item in content.info", :key="item.id")
                  .conf-page__content-top-info-right-item-title
                    | {{item.title}}
                  .conf-page__content-top-info-right-item-desc
                    | {{item.desc}}
              .conf-page__content-top-info-right-btns-wr
                a.conf-page__content-top-info-right-btn-info(v-if="content.btnInfo", :href="content.btnInfo.lnk" download)
                  SvgIcon.conf-page__content-top-info-right-btn-info-ico(name="download1" width="26" height="26")
                  .conf-page__content-top-info-right-btn-info-title
                    | {{content.btnInfo.title}}
                button.conf-page__content-top-info-right-btn-order(v-on:click="showPopupOrder()" type="button")
                  .conf-page__content-top-info-right-btn-order-title
                    | Интересует данное изделие

          .conf-page__content-top-chars(v-if="content.chars")
            .conf-page__content-top-chars-title
              | {{content.chars.title}}
            .conf-page__content-top-chars-desc(v-html="content.chars.desc")
            .conf-page__content-top-chars-desc-items(v-if="content.chars.items")
              .conf-page__content-top-chars-desc-item(v-for="item in content.chars.items", :key="item.id")
                .conf-page__content-top-chars-desc-item-left
                  | {{item.title}}
                .conf-page__content-top-chars-desc-item-right
                  | {{item.desc}}

        .conf-page__content-bottom
          .conf-page__content-bottom-title(v-if="content.bottomTitle")
            | {{content.bottomTitle}}
          .conf-page__content-bottom-btns-wr
            a.conf-page__content-top-info-right-btn-info(v-if="content.btnListDownload", :href="content.btnListDownload.lnk" download)
              SvgIcon.conf-page__content-top-info-right-btn-info-ico(name="download1" width="26" height="26")
              .conf-page__content-top-info-right-btn-info-title
                | {{content.btnListDownload.title}}
            router-link.conf-page__content-top-info-right-btn-online(v-if="content.btnListWrite", :to="content.btnListWrite.lnk" download)
              .conf-page__content-top-info-right-btn-online-title
                | {{content.btnListWrite.title}}

    Footer
</template>

<script>
  import EventBus from '@/eventBus';
  import Footer from '@/components/common/footer/Footer.vue';
  //import amazingSpinner from '@/components/common/amazingSpinner/amazingSpinner.vue';
  import SvgIcon from '@/components/common/svgIcon/SvgIcon.vue';

  import '@/components/configuratorPage/configuratorPage.styl';
  import {AjaxHelpersVue} from '@/mixins/AjaxHelpersVue';

  export default {
    name: 'ConfiguratorPage',

    mixins: [AjaxHelpersVue],

    data() {
      return {
        content: null
      }
    },

    components: {
      SvgIcon,
      Footer,
      //amazingSpinner
    },

    created() {
      // Загрузка данных для страницы
      EventBus.$on('loadConfiguratorPageSingle', params => {
        this.request(params.url);
      });
    },

    methods: {
      showPopupOrder() {
        EventBus.$emit('showPopupOrder', this.content.info);
      },

      request(url) {
        this.req('ConfiguratorPage:getConfiguratorPageSingle', {url}, res => {
          this.content = res.content;

          if(this.checkProperty(res.content)) {
            this.$nextTick(() => {

              this.checkOverflow(url);
            })
          }
        }, 'conf-page');
      }
    }
  }
</script>

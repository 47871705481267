<template lang="pug">
  .burger(:class="{'burger--show': burgerShow}", v-on:click="openCloseMenu()")
    span.burger__bar.burger__bar1
    span.burger__bar.burger__bar2
    span.burger__bar.burger__bar3
    span.burger__bar.burger__bar4
    span.burger__bar.burger__bar5

</template>

<script>
  import '@/components/common/burger/burger.styl';

  import EventBus from '@/eventBus';
  //import SvgIcon from '@/components/common/svgIcon/SvgIcon.vue';

  export default {
    name: 'Burger',

    data() {
      return {
        burgerShow: false
      }
    },

    components: {
      //SvgIcon
    },

    created() {
      EventBus.$on('burgerClose', () => {
        this.burgerClose();
      });
    },

    methods: {
      openCloseMenu() {
        this.burgerShow = !this.burgerShow;

        if(this.burgerShow) {
          EventBus.$emit('showMobileMenu');
        }
        else {
          EventBus.$emit('closeMobileMenu');
        }
      },

      burgerClose() {
        this.burgerShow = false;
      }
    }
  }
</script>

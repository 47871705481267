<template lang="pug">
  .main__section-main(v-if="section1")
    .main__section-main-img-wr(v-if="section1.image.path", :data-src="section1.image.path")
      img.main__section-main-img.main__section-main-img--hide(:src="section1.image.path" :alt="section1.image.name")
    h2.main__section-main-headline(v-if="section1.subtitle", v-html="section1.subtitle")

</template>

<script>
  import EventBus from '@/eventBus';

  import {AjaxHelpersVue} from '@/mixins/AjaxHelpersVue';
  import {ImagePreloaderVue} from '@/mixins/ImagePreloaderVue';

  export default {
    name: 'SectionOne',

    mixins: [AjaxHelpersVue, ImagePreloaderVue],

    props: ['section1'],

    data() {
      return {
        content: null
      }
    },

    components: {},

    created() {
      this.$nextTick(() => {
        this.imgPreloader('main__section-main-img-wr', 'main__section-main-img', '', 250);
      });
    },

    methods: {}

  }
</script>


<template lang="pug">
  .main__section.main__section--contact(v-if="section7")
    .main__section-map(v-if="section7.initMapConfig")
      .main__section-map-preloader
      #mainMap
        .main__section-map-button(v-on:click="hideMap($event, section7.initMapConfig)")
          SvgIcon.main__section-map-button-icon(name="close" width="34" height="34")
      .main__section-map-block
        p.main__section-map-description Нажмите для просмотра карты
        .main__section-map-circle(v-on:click="showMap($event)")
          SvgIcon.main__section-map-circle-icon(name="plus")
    .main__section-block.main__section-block--map
      h2.main__section-headline(v-if="section7.subtitle", v-html="section7.subtitle")
      .main__section-scroll.main__section-scroll--seven
        .main__section-scroll-wr
          .main__section-contacts(v-if="section7.addreses")
            .main__section-contact-wr(v-for="(item, index) in section7.addreses" :key="index")
              .main__section-contact
                SvgIcon.main__section-contact-icon(name="location" width="22" height="29")
                .main__section-contact-address(v-html='item.name')
              .main__section-contact(v-if="item.phone.length" v-for="(phone, index) in item.phone" :key="index")
                SvgIcon.main__section-contact-icon(name="phone" width="18" height="29")
                a.main__section-contact-link(:href="phone.link") {{ phone.text }}
          .main__section-button.main__section-button--lite(v-if="section7.button", @click="openPopup")
            .main__section-button-text {{section7.button}}
      Samedia
</template>

<script>
  import EventBus from '@/eventBus';
  import SvgIcon from '@/components/common/svgIcon/SvgIcon.vue';
  import Samedia from '@/components/common/samedia/Samedia.vue'

  import {AjaxHelpersVue} from '@/mixins/AjaxHelpersVue';
  import {YaHelpersVue} from '@/mixins/YaHelpersVue';
  import {FormHelpersVue} from '@/mixins/FormHelpersVue';
  import {SlideHelpers} from '@/mixins/SlideHelpers';
  import {ScrollbarHelpers} from "@/mixins/ScrollbarHelpers";


  export default {
    name: 'SectionSeven',

    mixins: [AjaxHelpersVue, YaHelpersVue, FormHelpersVue, SlideHelpers, ScrollbarHelpers],

    props: ['section7'],

    data() {
      return {
        content: null,
        isMapInit: false,
        name: '',
        phone: '',
        email: '',
        comment: '',
        formSend: false
      }
    },

    components: {
      SvgIcon,
      Samedia
    },

    created() {
      if(this.section7.initMapConfig) {
        this.$nextTick(() => {
          if(!this.isMapInit) {
            this.initMapLocal(this.section7.initMapConfig);
          }

          if($(window).width() > 1280) {
            this.customScrollbarInit('.main__section-scroll--seven');
          }
        });
      }
    },

    methods: {
      initMapLocal(initMapConfig) {
        // Передаем в карту её ид и объект настроек
        this.initMap('#mainMap', initMapConfig)
        // Когда карта загружена
          .then(() => {
            // Добавляем на карту placemarks
            this.addAllPlacemarks(initMapConfig.coords).then(() => {
              setTimeout(() => {
                $('.main__section-map-preloader').addClass('main__section-map-preloader--hide');
              }, 750);
            });
            this.isMapInit = true;
          });
      },
    }

  }
</script>

import EventBus from '@/eventBus';

export const AjaxHelpersVue = {
  data() {
    return {
      h1: ''
    }
  },

  methods: {

    req(api, data, callback, blockId, queryType = 'common') {
      data = data || {};

      EventBus.$emit('preloaderShow');

      PrologueClient.queryToServer(api, data, res => {

        if(!this.check404(res) && blockId !== 'page-404') {
          EventBus.$emit('load404Page');

          return false;
        }

        if(queryType === 'common') {
          this.queryCommon(callback, blockId, res);
        }
        if(queryType === 'popup') {
          this.queryPopup(res);
        }
        if(queryType === 'form') {
          this.queryForm(callback, res);
        }
        if(queryType === 'localPageRequest') {
          this.queryLocalPageRequest(callback, res);
        }

        //EventBus.$emit('preloaderHide');
      });
    },

    check404(res) {
      if(this.checkProperty(res.status) && res.status == '404') {
        return false;
      }

      return true;
    },

    queryCommon(callback, blockId, res) {
      // Данные для общих элементов всего сайта
      EventBus.$emit('pageLoaded', {
        header: res.header,
        menuTop: res.menuTop,
        //footer: res.footer
      });

      // Установить meta для страницы
      if(this.checkProperty(res.meta)) {
        this.setMeta(res.meta);
      }

      // Первая загрузка
      this.setPageLoaded();

      this.setPageBlockVisible(blockId);

      if($.isFunction(callback)) {
        callback(res);
      }
    },

    queryLocalPageRequest(callback, res) {
      if($.isFunction(callback)) {
        callback(res);
      }
    },

    queryPopup(res) {
      EventBus.$emit('popupDataLoaded', res);
    },

    queryForm(callback, res) {
      if($.isFunction(callback)) {
        callback(res);
      }

      EventBus.$emit('preloaderHide');
    },

    // Выбор активного блока для страницы
    setPageBlockVisible(blockId) {
      // Перемещение страницы к самому верху
      /*$('html, body').animate({
        scrollTop: 0
      }, 0);*/

      // Может понадобиться
      //EventBus.$emit('changePage', blockId);

      $('.page__item--active').removeClass('page__item--active');
      $('.page__item--anima').removeClass('page__item--anima');

      let $activeBlock = $(`.page__item[data-id=${blockId}]`);
      $activeBlock.addClass('page__item--active');

      $('.page').attr('data-block', blockId);

      // Для корректной работы анимации, тк для блоков применяется display: none; который устанавливает высоту абсолютного блока бзе дополнительных скриптов
      setTimeout(() => {
        $activeBlock.addClass('page__item--anima');

        EventBus.$emit('preloaderHide');
      }, 20);
      // или это
      // $('.page__items').css('min-height', $activeBlock.height() + 'px');
    },

    setMeta(res) {
      if(res.title.length) {
        $('title').text(res.title);
      }

      if(res.description.length) {
        $('[name="description"]').attr('content', res.description);
      }

      if(res.h1.length) {
        this.h1 = res.h1;
      }
    },

    // Для первой загрузки
    setPageLoaded() {
      let $page = $('.page');

      if(!$page.hasClass('page--loaded')) {
        $page.addClass('page--loaded');

        //this.pageLoaded = true;
      }
    },

    checkProperty(prop) {
      return typeof(prop) !== 'undefined' && $.type(prop) !== 'null';
    },

    /**
     * Проверка загрузки изображений
     * @param objImages {object}
     * @returns {Promise}
     * @description Формат принимаего объекта, где loaded - статус изображения по-умолчанию не загружен; src - ссылка на изображение
     var objImages = [
     {
       loaded: false,
       src: img1Src
     },
     {
       loaded: false,
       src: img2Src
     }
     ];
     */
    checkImgLoading(objImages) {
      return new Promise((resolve, reject) => {
        let loaded = false;

        $.each(objImages, (i, el) => {
          $('<img />').attr('src', el.src).on('load', function() {
            $(this).remove();

            objImages[i].loaded = true;
          });
        });

        let int = setInterval(() => {
          $.each(objImages, (i, el) => {
            loaded = el.loaded ? true : false;
          });

          if(loaded) {
            clearInterval(int);

            // Все изображения загрузились, резолвим
            resolve();
          }
        }, 250);
      });
    },

    checkOverflow(path) {
      if($(window).width() > 1200) {
        if(path === '/') {
          this.$nextTick(() => {
            $('.page').css('overflow', 'hidden');
            $('.page__items').css('height', '100%');
            $('.footer').css('bottom', '');
          });
        }
        else {
          this.$nextTick(() => {
            $('.page').css('overflow', 'unset');
            $('.page__items').css('height', 'auto');
            $('.footer').css('bottom', '0');
          });
        }
      }
    },

    // todo сделать для vue
    /*chkSingleImgLoad() {
      $('.PrologueUniRu__templates-content-right-item-img').each(function(i, el) {
        $('<img />').attr('src', $(el).attr('src')).on('load', function() {
          $(this).remove();

          $(el)
            .parents('.PrologueUniRu__templates-content-right-item-img-wr')
            .find('.PrologueUniRu__templates-content-right-item-img-preloader')
            .addClass('PrologueUniRu__templates-content-right-item-img-preloader--hide');
        });
      })
    }*/
  }
};

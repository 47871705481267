<template lang="pug">
  .products-item(v-if="content")
    .page__wr
      ul.products-item__nav(v-if="content.nav")
        li.products-item__nav-item(v-for="(item, index) in content.nav" :key="index" :class="{'products-item__nav-item--active': index == content.nav.length-1}")
          router-link.products-item__nav-item-button(:to="item.link") {{ item.name }}
      .products-item__container
        .products-item__container-left
          .products-item__slider(v-if="content.slider")
            .swiper-container
              .swiper-wrapper
                .swiper-slide.products-item__swiper-slide(v-for="image in content.slider" :key="image.id" :data-id="image.id")
                  .products-item__slider-item
                    .products-item__slider-item-layout
                    .products-item__slider-item-img-wr(:data-src="image.path")
                      img.products-item__slider-item-img.products-item__slider-item-img--hide(:src='image.path' :alt="image.name")
            .products-item__slider-navigation
              .swiper-button-prev
                SvgIcon(class='products-item__slider-ico' name="arrow" width="22" height="34")
              .products-item__slider-pagination.swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets
              .swiper-button-next
                SvgIcon(class='products-item__slider-ico' name="arrow" width="22" height="34")
          a.products-item__container-left-button(v-if="content.catalog", :href="content.catalog")
            SvgIcon(class='products-item__container-left-button-ico' name="download" width="28" height="34")
            .products-item__container-left-button-text Скачать каталог
          .products-item__button.products-item__button--lite(v-if="content.button", @click="openPopup")
            .products-item__button-text {{content.button}}
          .products-item__popup
            form.products-item__popup-form(v-on:submit.prevent="submit" :class="{'products-item__popup-form--show': !formSend}")
              h2.products-item__popup-form-legend Оставить заявку
              .products-item__popup-form-block
                input.products-item__popup-form-input(type="text" placeholder="Ваше имя" v-model="name" required)
                input.products-item__popup-form-input(type="email" placeholder="E-mail" v-model="email" required)
              input.products-item__popup-form-input(type="tel" name="phone" v-model="phone" v-mask="'+7(###)-###-##-##'" placeholder="+7(___)-___-__-__" required)
              textarea.products-item__popup-form-textarea(placeholder="Комментарий" v-model="comment" required)
              button.products-item__popup-form-button(type="submit")
                .products-item__popup-form-button-text Отправить
              .products-item__popup-form-description
                p.products-item__popup-form-description-text Нажимая кнопку «Отправить», я даю согласие на
                  br
                  router-link(to="/politika-konfidencialnosti" target="_blank").products-item__popup-form-description-text--link  обработку персональных данных
            .products-item__popup-form-send(:class="{'products-item__popup-form-send--show': formSend}")
              h5.products-item__popup-form-send-text Ваша заявка успешно отправлена!
        .products-item__container-right
          .products-item__container-right-title(v-if="content.title") {{content.title}}
          ul.products-item__container-right-list(v-if="content.characteristics")
            li.products-item__container-right-list-item(v-for="(item, index) in content.characteristics" :key="index") {{ item.text }}
    Footer
</template>

<script>
  import EventBus from '@/eventBus';

  import '@/components/productsItem/productsItem.styl';
  import SvgIcon from '@/components/common/svgIcon/SvgIcon.vue';
  import {AjaxHelpersVue} from '@/mixins/AjaxHelpersVue';
  import {SliderHelpersVue} from "@/mixins/SliderHelpersVue";
  import {FormHelpersVue} from "@/mixins/FormHelpersVue";
  import {ImagePreloaderVue} from '@/mixins/ImagePreloaderVue';
  import Footer from '@/components/common/footer/Footer';

  export default {
    name: 'ProductsItem',
    mixins: [AjaxHelpersVue, SliderHelpersVue, FormHelpersVue, ImagePreloaderVue],

    data() {
      return {
        content: null,
        name: '',
        phone: '',
        email: '',
        comment: '',
        formSend: false,
      }
    },

    components: {
      SvgIcon,
      Footer
    },

    created() {
      // Загрузка данных для страницы
      EventBus.$on('loadProductsItem', params => {
        this.request(params.url);
      });
    },

    methods: {
      request(url) {
        this.req('ProductsPage:getProductsItem', {url}, res => {
          this.content = res.content;
          this.$nextTick(() => {
            this.checkOverflow(url);
            this.initSlider('.products-item__slider', 'products-item__slider', '.products-item__list-item--dinamic', 'products-item__list-item--dinamic--active');
            this.imgPreloader('products-item__slider-item-img-wr', 'products-item__slider-item-img', '', 250);
          })
        }, 'products-item');
      },

      showImage(event) {
        let elem = event.target;
        let tag  = elem.nodeName;
        let id, index;
        if(tag === 'A') {
          id = $(elem).parent().attr('data-id');
        }
        if(tag === 'LI') {
          id = $(elem).attr('data-id');
        }
        index = $('.products-item__slider').find('.products-item__swiper-slide[data-id="' + id + '"]').index();
        this.swiper.slideTo(index, 300);
        $('.products-item__list-item--dinamic').removeClass('products-item__list-item--dinamic--active');
        $($('.products-item__list-item--dinamic')[index]).addClass('products-item__list-item--dinamic--active');
      },

      openPopup() {
        $.fancybox.open({
          src: '.products-item__popup',
          type: 'inline',
          opts: {
            //animationEffect: "zoom-in-out",
            beforeShow: function(instance, current) {
              $('.page').addClass('popup popup--effect');
            },
            beforeClose: function(instance, current) {
              $('.page').removeClass('popup popup--effect');
            },
            touch: false,
            buttons: ['close'],
          }
        });
      },

      submit() {
        let data = {
          name: this.name,
          email: this.email,
          phone: this.phone,
          comment: this.comment
        };

        this.submitForm('Common:sendOrderForm', data, res => {
          this.name    = '';
          this.email   = '';
          this.phone   = '';
          this.comment = '';
          if(res.status === 'ok') {
            this.formSend = true;
          }
        });
      },
    }
  }
</script>

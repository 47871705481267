import 'normalize.css';
import '@/assets/stylus/common.styl';

import 'swiper/dist/css/swiper.min.css';
import '@fancyapps/fancybox/dist/jquery.fancybox.min.css';
import 'malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css';
import 'perfect-scrollbar/css/perfect-scrollbar.css';

window.$ = window.jQuery = require('jquery');

window.Swiper   = require('swiper/dist/js/swiper.min.js');
window.fancybox = require('@fancyapps/fancybox/dist/jquery.fancybox.min.js');
window.mCustomScrollbar = require('malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.concat.min.js');
window.imagesLoaded = require('imagesloaded/imagesloaded.pkgd.js');
window.PerfectScrollbar = require('perfect-scrollbar/dist/perfect-scrollbar.js');

import Vue from 'vue';
import App from './App.vue';
import router from './router';
import './registerServiceWorker';
import store from './store';
import VueMask from 'v-mask';

Vue.use(VueMask);

//Vue.use(VueResource);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');

import EventBus from '@/eventBus';

export const SliderHelpersVue = {

  data() {
      return {
          swiper: null
      }
  },

  methods: {
    /**
     * mounted() {
        this.initSlider('.js-slider-about-content', {
          slidesPerView: 1
        });
      }
     * @param sliderClass
     * @param params
     */
    initSlider(sliderClass, slideClass, linkClass, activeLinkClass, params) {
      let $slider           = $(sliderClass);
      let $sliderNextBtn    = $slider.find('.swiper-button-next');
      let $sliderPrevBtn    = $slider.find('.swiper-button-prev');
      let $sliderPagination = $slider.find('.swiper-pagination');
      let that = this;

      let slidesCount = $slider.find('.swiper-slide').length;

      let sliderParams = {};

      let sliderParamsDefault = {
        init: false,
        centeredSlides: true,
        speed: 600,
        spaceBetween: 0,
        slideDuplicateClass: 'swiper-slide-duplicate',
        //autoplay: {
        //  delay: 5000
        //},
        slidesPerView: 1,
        navigation: {
          nextEl: $sliderNextBtn,
          prevEl: $sliderPrevBtn
        },
        pagination: {
          el: $sliderPagination,
          type: 'bullets',
          clickable: true,
        },
        // loop: true, // в цикле не работают события вью из-за клонирования слайдов без событий
        // simulateTouch: true,
        observer: true, // если слайдер изначально скрыт
        observeParents: true, // если слайдер изначально скрыт
        on: {
            init: function() {
                that.slideClasses(sliderClass, slideClass);
            },

            transitionStart: function() {
                that.slideClasses(sliderClass, slideClass);
            },

            slideChange: function () {
                that.slideChange(linkClass, activeLinkClass);
            }
        }
      };

      if(typeof sliderParams !== 'undefined') {
        sliderParams = Object.assign(sliderParamsDefault, params);
      }

      if(slidesCount <= 1 ) {
          $.extend(sliderParams, {
              loop: false,
              virtualTranslate: true,
              centeredSlides: false
          });

          $sliderNextBtn.hide();
          $sliderPrevBtn.hide();
          $sliderPagination.hide();

          if(params == undefined) {
              $(sliderClass).addClass(slideClass + '--center');
              $(linkClass).removeClass(activeLinkClass);
              $($(linkClass)[0]).addClass(activeLinkClass);
          }
      } else {
          $sliderNextBtn.show();
          $sliderPrevBtn.show();
          $sliderPagination.show();

          if(params == undefined) {
            $(sliderClass).removeClass(slideClass + '--center');
          }
      }

      if($slider.length) {
        if(this.swiper) {
          this.swiper.destroy();
          this.swiper = null;
        }

        this.swiper = new Swiper($slider.find('.swiper-container'), sliderParams);

        this.swiper.init();
        // EventBus.$emit('SwiperInit', this.swiper);

        if(params == undefined) {
            this.swiper.slideTo(1, 300);
        }
      }
    },

    slideClasses(sliderClass, slideClass) {
        $(sliderClass).addClass(slideClass+'--effect');

        let $slides      = $(sliderClass).find('.swiper-slide');
        let $activeSlide = $slides.filter('.swiper-slide-active');

        let $prevSlides = $activeSlide.prevAll();
        let $nextSlides = $activeSlide.nextAll();

        $slides.removeClass(slideClass+'--effect');

        $nextSlides.addClass(slideClass+'--effect');

        $prevSlides.addClass(slideClass+'--effect');
    },

    slideChange(linkClass, activeLinkClass) {
      if(this.swiper) {
          let index = this.swiper.activeIndex;
          let $item = $(linkClass);
          $item.removeClass(activeLinkClass);
          $($item[index]).addClass(activeLinkClass);
      }
    },
  }
};

<template lang="pug">
  .main(v-if="content")
    .main__sections
      MenuSide(:menu="content.menuItems")
      .main__sections-bg
      .page__wr
        SectionOne.slide(:section1="content.contents[0]" :data-id="content.menuItems[0].id")
        SectionTwo.slide(:section2="content.contents[1]" :data-id="content.menuItems[1].id")
        SectionThree.slide(:section3="content.contents[2]" :data-id="content.menuItems[2].id")
        SectionFour.slide(:section4="content.contents[3]" :data-id="content.menuItems[3].id")
        SectionFive.slide(:section5="content.contents[4]" :data-id="content.menuItems[4].id")
        SectionSix.slide(:section6="content.contents[5]" :data-id="content.menuItems[5].id")
        SectionSeven.slide(:section7="content.contents[6]" :data-id="content.menuItems[6].id")
    Footer
</template>

<script>
  import EventBus from '@/eventBus';

  import '@/components/main/main.styl';
  import {AjaxHelpersVue} from '@/mixins/AjaxHelpersVue';
  import {SlideHelpers} from '@/mixins/SlideHelpers';
  import MenuSide from '@/components/main/menuSide/MenuSide.vue';
  import SectionOne from '@/components/main/section1/SectionOne.vue';
  import SectionTwo from '@/components/main/section2/SectionTwo.vue';
  import SectionThree from '@/components/main/section3/SectionThree.vue';
  import SectionFour from '@/components/main/section4/SectionFour.vue';
  import SectionFive from '@/components/main/section5/SectionFive.vue';
  import SectionSix from '@/components/main/section6/SectionSix.vue';
  import SectionSeven from '@/components/main/section7/SectionSeven.vue';
  import Footer from '@/components/common/footer/Footer';

  // @ is an alias to /src
  //import Events from '@/components/events/Events.vue';

  export default {
    name: 'Main',

    mixins: [AjaxHelpersVue, SlideHelpers],

    data() {
      return {
        content: null,
        contents: null,
        slideScroll: true
      }
    },

    components: {
      MenuSide,
      SectionOne,
      SectionTwo,
      SectionThree,
      SectionFour,
      SectionFive,
      SectionSix,
      SectionSeven,
      Footer
    },

    created() {
      // Загрузка данных для страницы
      EventBus.$on('loadMainPage', params => {
        this.request(params.url);
      });

      // Отключить скролл экранов
      EventBus.$on('disableSlideScroll', params => {
        if(this.slideScroll === true) {
          this.disableScroll();
          this.slideScroll = false;
        }

      });

      // Включить скролл экранов
      EventBus.$on('enableSlideScroll', params => {
        if(this.slideScroll === false) {
          this.enableScroll();
          this.slideScroll = true;
        }
      });
    },

    methods: {
      request(url) {
        this.req('MainPage:getMainPage', {url}, res => {
          if(this.checkProperty(res.content)) {
            this.content = res.content;
            this.contents = this.content.contents;
            this.$nextTick(() => {
              setTimeout(() => {
                if($(window).width() >= 1280) {
                  this.initSlide();
                }
              }, 500);

              this.changeStyle();
              this.checkOverflow(url);
            });
          }
        }, 'main');
      },

      /*popupLocal(type, action) {
        this.popup(type, action);
      }*/
    }

  }
</script>

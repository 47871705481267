<template lang="pug">
  .conf(v-if="content")
    .page__wr
      .conf__content
        h1.conf__title
          | {{h1}}
        .conf__items(v-if="content.items")
          router-link.conf__item(v-for="item in content.items", :to="item.lnk", :key="item.id")
            .conf__item-img-wr
              img.conf__item-img(:src="item.img")
            .conf__item-title
              | {{item.title}}
    Footer
</template>

<script>
  import EventBus from '@/eventBus';
  import Footer from '@/components/common/footer/Footer.vue'

  import '@/components/configurator/configurator.styl';
  import {AjaxHelpersVue} from '@/mixins/AjaxHelpersVue';

  export default {
    name: 'Contacts',

    mixins: [AjaxHelpersVue],

    data() {
      return {
        content: null
      }
    },

    components: {
      //SvgIcon,
      Footer
    },

    created() {
      // Загрузка данных для страницы
      EventBus.$on('loadConfiguratorPage', params => {
        this.request(params.url);
      });
    },

    methods: {
      request(url) {
        this.req('ConfiguratorPage:getConfiguratorPage', {url}, res => {
          this.content = res.content;

          if(this.checkProperty(res.content)) {
            this.$nextTick(() => {

              this.checkOverflow(url);
            })
          }
        }, 'conf');
      }
    }
  }
</script>

import Vue from 'vue';
import Router from 'vue-router';
import Index from './components/index/Index';

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  linkExactActiveClass: '',
  linkActiveClass: '',
  routes: [
    {
      path: '/produkciya',
      name: 'Products',
      component: Index,
      children: [
        {
          path: ':category',
          component: Index,
          name: 'ProductsCategory',
          children: [
            {
              path: ':item',
              component: Index,
              name: 'ProductsItem'
            }
          ]
        }
      ]
    },
    {
      path: '/vipolnennye-obekty',
      name: 'Facilities',
      component: Index,
      children: [
        {
          path: ':category',
          component: Index,
          name: 'FacilitiesCategory',
          children: [
            {
              path: ':item',
              component: Index,
              name: 'FacilitiesItem'
            }
          ]
        }
      ]
    },

    {
      path: '/configurator',
      name: 'Configurator',
      component: Index,
      children: [
        {
          path: 'oprosnyj-list-nasosy',
          component: Index,
          name: 'ConfiguratorPageListPump'
        },
        {
          path: 'oprosnyj-list-pozharotushenija',
          component: Index,
          name: 'ConfiguratorPageListFire'
        },
        {
          path: ':type',
          component: Index,
          name: 'ConfiguratorType',
          children: [
            {
              path: 'bez-rezultata',
              component: Index,
              name: 'ConfiguratorPageNoResult'
            },
            {
              path: ':page',
              component: Index,
              name: 'ConfiguratorPage'
            }
          ]
        }
      ]
    },
    /*{
      path: '/menyu',
      name: 'Menu',
      component: Index,
      children: [
        {
          path: 'tip/:tipId',
          component: Index,
          name: 'MenuInside',
          children: [
            {
              path: 'kategoriya/:kategoriyaId',
              component: Index,
              name: 'MenuInside',
              children: [
                {
                  path: 'vino/:vinoId',
                  component: Index,
                  name: 'MenuInside'
                }
              ]
            }
          ]
        }
      ]
    },*/

    {
      path: '*',
      name: 'Index',
      component: Index
    }
  ]
})

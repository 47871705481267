<template lang="pug">
  label.chk
    input.chk__el(v-model="data.checked", type="checkbox")
    .chk__checkmark
    | {{data.title}}
</template>

<script>
  import '@/components/common/chk/chk.styl';

  import SvgIcon from '@/components/common/svgIcon/SvgIcon.vue';

  export default {
    name: 'Chk',

    props: ['data'],

    components: {
      SvgIcon
    },

    methods: {

    }
  }
</script>

<template lang="pug">
  .partners(v-if="content")
    .partners__left-bg
    .page__wr
      .partners__main
        .partners__left
          ul.partners__nav(v-if="content.nav")
            li.partners__nav-item(v-for="(item, index) in content.nav" :key="index" :class="{'partners__nav-item--active': index == content.nav.length-1}")
              router-link.partners__nav-item-button(:to="item.link") {{ item.name }}
        .partners__right
          .partners__list(v-if="content.items")
            a.partners__list-item(v-for="item in content.items" :key="item.id" :href="item.link" target="_blank")
              .partners__list-item-logo-wr(:data-src="item.image")
                img.partners__list-item-logo.partners__list-item-logo--hide(:src="item.image")
                .partners__list-item-logo-preloader
              .partners__list-item-title(v-if="item.title") {{item.title}}
    Footer
</template>

<script>
  import EventBus from '@/eventBus';

  import '@/components/partners/partners.styl';
  import SvgIcon from '@/components/common/svgIcon/SvgIcon.vue';
  import {AjaxHelpersVue} from '@/mixins/AjaxHelpersVue';
  import {ScrollbarHelpers} from "@/mixins/ScrollbarHelpers";
  import {ImagePreloaderVue} from '@/mixins/ImagePreloaderVue';
  import Footer from '@/components/common/footer/Footer';

  export default {
    name: 'Partners',
    mixins: [AjaxHelpersVue, ScrollbarHelpers, ImagePreloaderVue],

    data() {
      return {
        content: null
      }
    },

    components: {
      SvgIcon,
      Footer
    },

    created() {
      // Загрузка данных для страницы
      EventBus.$on('loadPartnersPage', params => {
        this.request(params.url);
      });
    },

    methods: {
      request(url) {
        this.req('PartnersPage:getPartnersPage', {url}, res => {
          this.content = res.content;
          this.$nextTick(() => {
            this.checkOverflow(url);
            this.imgPreloader('partners__list-item-logo-wr', 'partners__list-item-logo', 'partners__list-item-logo-preloader', 250);
          })
        }, 'partners');
      },
    }
  }
</script>

<template lang="pug">
  .popupOrder
    form.popupOrder__popup-form(v-on:submit.prevent="submit" :class="{'popupOrder__popup-form--show': !formSend}")
      h2.popupOrder__popup-form-legend Оставить заявку
      .popupOrder__popup-form-block
        input.popupOrder__popup-form-input(type="text" placeholder="Ваше имя" v-model="name" required)
        input.popupOrder__popup-form-input(type="email" placeholder="E-mail" v-model="email" required)
      input.popupOrder__popup-form-input(type="tel" name="phone" v-model="phone" v-mask="'+7(###)-###-##-##'" placeholder="+7(___)-___-__-__" required)
      textarea.popupOrder__popup-form-textarea(placeholder="Комментарий" v-model="comment" required)
      button.popupOrder__popup-form-button(type="submit")
        .popupOrder__popup-form-button-text Отправить
      .popupOrder__popup-form-description
        p.popupOrder__popup-form-description-text Нажимая кнопку «Отправить», я даю согласие на
          br
          router-link.popupOrder__popup-form-description-text--link(to="/politika-konfidencialnosti" target="_blank")  обработку персональных данных
    .popupOrder__popup-form-send(:class="{'popupOrder__popup-form-send--show': formSend}")
      h5.popupOrder__popup-form-send-text Ваша заявка успешно отправлена!

</template>

<script>
  import EventBus from '@/eventBus';
  import SvgIcon from '@/components/common/svgIcon/SvgIcon.vue';

  // @ is an alias to /src
  import '@/components/common/popups/popupOrder/popupOrder.styl';

  import {AjaxHelpersVue} from '@/mixins/AjaxHelpersVue';
  import {FormHelpersVue} from "@/mixins/FormHelpersVue";

  export default {
    name: 'PopupCall',

    mixins: [AjaxHelpersVue, FormHelpersVue],

    components: {
      SvgIcon
    },

    data() {
      return {
        content: null,
        name: '',
        phone: '',
        email: '',
        comment: '',
        formSend: false,
        productInfo: null,
      }
    },

    created() {
      EventBus.$on('showPopupOrder', params => {
        this.productInfo = params;
        this.openPopup();
      });
    },

    methods: {
      openPopup() {
        $.fancybox.open({
          src: '.popupOrder',
          type: 'inline',
          opts: {
            //animationEffect: "zoom-in-out",
            beforeShow: function(instance, current) {
              $('.page').addClass('popup popup--effect');
            },
            beforeClose: function(instance, current) {
              $('.page').removeClass('popup popup--effect');
            },
            touch: false,
            buttons: ['close'],
          }
        });
      },

      submit() {
        let data = {
          name: this.name,
          email: this.email,
          phone: this.phone,
          comment: this.comment,
          art: this.productInfo[0].desc,
          codeSHU: this.productInfo[1].desc,
          codeNum: this.productInfo[2].desc
        };

        this.submitForm('Common:sendOrderForm', data, res => {
          this.name    = '';
          this.email   = '';
          this.phone   = '';
          this.comment = '';

          if(res.status === 'ok') {
            this.formSend = true;
          }
        });
      }
    }
  }
</script>

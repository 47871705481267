<template lang="pug">
  .facilities(v-if="content")
    .page__wr
      ul.facilities__nav(v-if="content.nav")
        li.facilities__nav-item(v-for="(item, index) in content.nav" :key="index" :class="{'facilities__nav-item--active': index == content.nav.length-1}")
          router-link.facilities__nav-item-button(:to="item.link") {{ item.name }}
      .facilities__main
        .facilities__left
          .facilities__title(v-if="content.title") {{content.title}}
          .facilities__categories(v-if="content.categories")
            router-link.facilities__categories-item(v-for="category in content.categories", :key="category.id", :to="category.link")
              .facilities__categories-item-text {{category.name}}
        .facilities__right
          .facilities__img-wr(v-if="content.image", :data-src="content.image")
            img.facilities__img.facilities__img--hide(:src="content.image")
          .facilities__subtitle(v-if="content.subtitle", v-html="content.subtitle")
          .facilities__text(v-if="content.text", v-html="content.text")
    Footer
</template>

<script>
  import EventBus from '@/eventBus';

  import '@/components/facilities/facilities.styl';
  import SvgIcon from '@/components/common/svgIcon/SvgIcon.vue';
  import {AjaxHelpersVue} from '@/mixins/AjaxHelpersVue';
  import {ScrollbarHelpers} from "@/mixins/ScrollbarHelpers";
  import {ImagePreloaderVue} from '@/mixins/ImagePreloaderVue';
  import Footer from '@/components/common/footer/Footer';

  export default {
    name: 'FacilitiesItem',
    mixins: [AjaxHelpersVue, ScrollbarHelpers, ImagePreloaderVue],

    data() {
      return {
        content: null
      }
    },

    components: {
      SvgIcon,
      Footer
    },

    created() {
      // Загрузка данных для страницы
      EventBus.$on('loadFacilitiesItem', params => {
        this.request(params.url);
      });
    },

    methods: {
      request(url) {
        this.req('FacilitiesPage:getFacilitiesItem', {url}, res => {
          this.content = res.content;
          this.$nextTick(() => {
            this.checkOverflow(url);
            this.imgPreloader('facilities__img-wr', 'facilities__img', '', 250);
          })
        }, 'facilities-item');
      },
    }
  }
</script>

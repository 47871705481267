<template lang="pug">
  .mobile-menu(v-if="menuTop", :class="{'mobile-menu--show': menuShow}")
    .mobile-menu__content
      .mobile-menu__content-logo-wr
        .mobile-menu__content-logo-left
          router-link.mobile-menu__content-logo-lnk(to="/")
            img.mobile-menu__content-logo(src="@/assets/img/logo-mobile.svg" alt="")
      .mobile-menu__content-items-wr
        .mobile-menu__content-items(v-if="menuTop.items")
          router-link.mobile-menu__content-item(v-on:click.native="closeMenu()", v-for="item in menuTop.items", :to="item.link", :key="item.id")
            | {{item.title}}

        .mobile-menu__content-info
          .mobile-menu__address(v-if="header.address")
            .mobile-menu__address-item(v-for="item in header.address", :key="item.id", v-html="item.text")
          .mobile-menu__phone(v-if="header.phone")
            a.mobile-menu__phone-item(v-for="item in header.phone", :key="item.id", :href="item.link")
              | {{item.title}}

</template>

<script>
  import '@/components/common/mobileMenu/mobileMenu.styl';
  import SvgIcon from '@/components/common/svgIcon/SvgIcon.vue';
  import EventBus from '@/eventBus';

  export default {
    name: 'MobileMenu',

    data() {
      return {
        menuTop: null,
        header: null,

        menuShow: false
      }
    },

    components: {
      SvgIcon
    },

    created() {
      // Данные страницы загружены
      EventBus.$on('pageLoaded', res => {
        if(res.menuTop) {
          this.menuTop = res.menuTop;
        }

        if(res.header) {
          this.header = res.header;
        }

        this.$nextTick(() => {
          this.initScroll();
        });
      });

      EventBus.$on('closeMobileMenu', res => {
        this.closeMenu();
      });

      EventBus.$on('showMobileMenu', res => {
        this.showMenu();
      });
    },

    methods: {
      closeMenu() {
        this.menuShow = false;

        $('#app').removeClass('overflow');

        EventBus.$emit('burgerClose');
      },

      showMenu() {
        this.menuShow = true;

        setTimeout(() => {
          $('#app').addClass('overflow');
        }, 250);
      },

      initScroll() {
        if($('.mobile-menu__content-items-wr').length) {
          new PerfectScrollbar($('.mobile-menu__content-items-wr')[0], {
            wheelSpeed: 0.25,
            wheelPropagation: true,
            minScrollbarLength: 20,
            maxScrollbarLength: 150
          });
        }
      }
    }

  }
</script>

<template lang="pug">
  .sertificates(v-if="content")
    .page__wr
      ul.sertificates__nav(v-if="content.nav")
        li.sertificates__nav-item(v-for="(item, index) in content.nav" :key="index" :class="{'sertificates__nav-item--active': index == content.nav.length-1}")
          router-link.sertificates__nav-item-button(:to="item.link") {{ item.name }}
      .sertificates__list(v-if="content.items")
        a.sertificates__list-item(v-for="item in content.items" :key="item.id" :href="item.link" data-fancybox="gallery")
          .sertificates__list-item-img-wr(:data-src="item.image")
            img.sertificates__list-item-img.sertificates__list-item-img--hide(:src="item.image")
    Footer
</template>

<script>
  import EventBus from '@/eventBus';

  import '@/components/sertificates/sertificates.styl';
  import SvgIcon from '@/components/common/svgIcon/SvgIcon.vue';
  import {AjaxHelpersVue} from '@/mixins/AjaxHelpersVue';
  import {ScrollbarHelpers} from "@/mixins/ScrollbarHelpers";
  import {ImagePreloaderVue} from '@/mixins/ImagePreloaderVue';
  import Footer from '@/components/common/footer/Footer';

  export default {
    name: 'Sertificates',
    mixins: [AjaxHelpersVue, ScrollbarHelpers, ImagePreloaderVue],

    data() {
      return {
        content: null
      }
    },

    components: {
      SvgIcon,
      Footer
    },

    created() {
      // Загрузка данных для страницы
      EventBus.$on('loadSertificatesPage', params => {
        this.request(params.url);
      });
    },

    methods: {
      request(url) {
        this.req('SertificatesPage:getSertificatesPage', {url}, res => {
          this.content = res.content;
          this.$nextTick(() => {
            this.checkOverflow(url);
            this.initFancybox();
            this.imgPreloader('sertificates__list-item-img-wr', 'sertificates__list-item-img', '', 250);
          })
        }, 'sertificates');
      },

      initFancybox() {
        $('.sertificates__list-item').fancybox({
          image: {
            preload: true,
          },
          clickContent: function(current, event) {
            return current.type === "image" ? "zoom" : false;
          },
          dblclickContent: false,
          dblclickSlide: false,
          dblclickOutside: false,
          buttons: ['close'],
          infobar: false,
          hash: false
        });
      },
    }
  }
</script>

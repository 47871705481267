<template lang="pug">
  .samedia
    .samedia__created
      a.samedia__created-lnk(href='http://samedia.ru', target='_blank') Создано в Samedia
    a(href='http://samedia.ru', target='_blank')
      img.samedia__logo(src='~@/assets/img/samedia.svg', alt='Samedia')
</template>

<script>
  import '@/components/common/samedia/samedia.styl';

  export default {
    name: 'Samedia',
  }
</script>

<template lang="pug">
  #app.page(v-on:click="globalClick($event)")
    .overlay
    Header

    router-view

    popupOrder

    Burger
    MobileMenu
</template>

<script>
  import EventBus from '@/eventBus';

  import Header from '@/components/common/header/Header';
  import popupOrder from '@/components/common/popups/popupOrder/popupOrder.vue';
  import MobileMenu from '@/components/common/mobileMenu/mobileMenu.vue';
  import Burger from '@/components/common/burger/burger.vue';

  export default {
    components: {
      Header,
      popupOrder,
      MobileMenu,
      Burger
    },

    methods: {
      // Закрыть попап по клику вне его
      globalClick(e) {
        let str = $(e.target).attr('class') || '';

        // Select conf
        if(str.indexOf('conf-type__block-item-select-wr') < 0
           && str.indexOf('conf-type__block-item-select-top') < 0
           && str.indexOf('conf-type__block-item-select-bottom-item') < 0)
        {
          EventBus.$emit('selectConfClose');
        }

        // Select list
        if(str.indexOf('select__block-item-select-wr') < 0
           && str.indexOf('select__block-item-select-top') < 0
           && str.indexOf('select__block-item-select-bottom-item') < 0)
        {
          EventBus.$emit('selectListClose');
        }
      }
    }
  }
</script>

export const ImagePreloaderVue = {
    methods: {
        imgPreloader(containerClass, imgClass, preloaderClass, animationDuration) {
            if(!animationDuration) {
                animationDuration = 0;
            }
            $('.' + containerClass).each(function( index ) {
                $($('.' + containerClass)[index]).imagesLoaded().progress( function( instance, image ) {
                    let result = image.isLoaded;
                    if(result) {
                        let $item = $('.' + containerClass + '[data-src="'+image.img.attributes.src.value+'"]');
                        if(preloaderClass) {
                            $item.find('.' + preloaderClass).addClass(preloaderClass + '--hide');
                        }
                        setTimeout(() => {
                            $item.find('.' + imgClass).removeClass(imgClass + '--hide');
                        }, animationDuration);
                    }
                });
            });
        }
    }
};

<template lang="pug">
  footer.footer

</template>

<script>
  import '@/components/common/footer/footer.styl';

  import EventBus from '@/eventBus';
  import SvgIcon from '@/components/common/svgIcon/SvgIcon.vue';

  export default {
    name: 'Footer',

    data() {
      return {
        footer: null
      }
    },

    components: {
      SvgIcon
    },

    created() {
      // Данные страницы загружены
      EventBus.$on('pageLoaded', res => {
        this.footer = res.footer;
      });

      //this.$nextTick(() => {

      //});
    },

    methods: {}

  }
</script>

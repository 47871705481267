<template lang="pug">
  .main__section(v-if="section3")
    .main__section-slider.main__section-slider--three(v-if="section3.slider")
      .swiper-container
        .swiper-wrapper
          .swiper-slide.main__section-swiper-slide(v-for="(image, index) in section3.slider" :key="index")
            .main__section-slider-item
              .main__section-slider-item-layout
              .main__section-slider-item-img-wr(:data-src="image.path")
                img.main__section-slider-item-img.main__section-slider-item-img--hide(:src='image.path' :alt="image.name")
      .main__section-slider-navigation
        .swiper-button-prev
          SvgIcon(class='main__section-slider-ico' name="arrow" width="22" height="34")
        .main__section-slider-pagination.swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets
        .swiper-button-next
          SvgIcon(class='main__section-slider-ico' name="arrow" width="22" height="34")
    .main__section-block
      h2.main__section-headline(v-if="section3.subtitle", v-html="section3.subtitle")
      .main__section-scroll.main__section-scroll--three
        .main__section-text(v-if="section3.text", v-html="section3.text")

</template>

<script>
  import EventBus from '@/eventBus';
  import SvgIcon from '@/components/common/svgIcon/SvgIcon.vue';
  import {SliderHelpersVue} from "@/mixins/SliderHelpersVue";
  import {ImagePreloaderVue} from '@/mixins/ImagePreloaderVue';
  import {ScrollbarHelpers} from "@/mixins/ScrollbarHelpers";

  export default {
    name: 'SectionThree',

    mixins: [SliderHelpersVue, ImagePreloaderVue, ScrollbarHelpers],

    props: ['section3'],

    data() {
      return {
        content: null
      }
    },

    components: {
      SvgIcon
    },

    created() {
      this.$nextTick(() => {
        this.initSlider('.main__section-slider--three', '.main__section-slider--three');
        this.customScrollbarInit('.main__section-scroll--three');
        this.imgPreloader('main__section-slider-item-img-wr', 'main__section-slider-item-img', '', 250);
      });
    },

    methods: {}

  }
</script>

<template lang="pug">
  .about(v-if="content")
    .page__wr
      .about__nav-wr
        ul.about__nav(v-if="content.nav")
          li.about__nav-item(v-for="(item, index) in content.nav" :key="index" :class="{'about__nav-item--active': index == content.nav.length-1}")
            router-link.about__nav-item-button(:to="item.link") {{ item.name }}
        .about__nav-right(v-if="content.navRight")
          router-link.about__nav-right-item(v-for="(item, index) in content.navRight" :key="index" :to="item.link")
            SvgIcon(:name="item.icon", width="35", height="35", class="about__nav-right-item-ico")
            .about__nav-right-item-text {{ item.name }}
      .about__main
        a.about__video(v-if="content.video.link", :href="content.video.link" data-fancybox)
          img.about__video-img(:src="content.video.image")
          SvgIcon(name="play", width="90", height="90", class="about__video-ico")
        .about__main-text-wr
          .about__main-text(v-if="content.text", v-html="content.text")
    Footer
</template>

<script>
  import EventBus from '@/eventBus';

  import '@/components/about/about.styl';
  import SvgIcon from '@/components/common/svgIcon/SvgIcon.vue';
  import {AjaxHelpersVue} from '@/mixins/AjaxHelpersVue';
  import {SliderHelpersVue} from "@/mixins/SliderHelpersVue";
  import {ScrollbarHelpers} from "@/mixins/ScrollbarHelpers";
  import {ImagePreloaderVue} from '@/mixins/ImagePreloaderVue';
  import Footer from '@/components/common/footer/Footer';

  export default {
    name: 'About',
    mixins: [AjaxHelpersVue, SliderHelpersVue, ScrollbarHelpers, ImagePreloaderVue],

    data() {
      return {
        content: null
      }
    },

    components: {
      SvgIcon,
      Footer
    },

    created() {
      // Загрузка данных для страницы
      EventBus.$on('loadAboutPage', params => {
        this.request(params.url);
      });

      // Данные страницы загружены
      EventBus.$on('pageLoaded', res => {
        let path = this.$route.path || '';

        this.$nextTick(() => {
          setTimeout(() => {
            this.setInitialActiveClass(path);
          }, 200);
        });
      });

      this.$nextTick(() => {
        setTimeout(() => {
          this.setActiveClass();
        }, 200);
      });
    },

    methods: {
      request(url) {
        this.req('AboutPage:getAboutPage', {url}, res => {
          this.content = res.content;
          this.$nextTick(() => {
            this.checkOverflow(url);
            this.imgPreloader('about__video', 'about__video-img', '', 250);
          })
        }, 'about');
      },

      setInitialActiveClass(path) {
        let pathArr = path.split('/');

        $('.about__nav-right-item').removeClass('about__nav-right-item--active');

        if(pathArr[2]) {
          $(`.about__nav-right-item[href*='${pathArr[2]}']`).addClass('about__nav-right-item--active');
        }
      },

      setActiveClass() {
        $(document).on('click', '.about__nav-right-item', function() {
          $('.about__nav-right-item')
            .filter('.about__nav-right-item--active')
            .removeClass('about__nav-right-item--active');

          $(this).addClass('about__nav-right-item--active');
        });
      }

    }
  }
</script>

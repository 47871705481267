// todo доделать под вью

// Пример в компоненте Contacts
export const YaHelpersVue = {

  data() {
    return {
      _myMap: null,
      _myCollection: null,
      _initConfig: null
    }
  },

  methods: {
    initMap(block, initMapConfig) {
      var deferred = $.Deferred();

      if(!$(block).length) {
        // Карты нет
        deferred.reject();
      }
      else {
        this._initConfig = initMapConfig || {};

        ymaps.ready(() => {
          /*
            Если это поместить третьим параметром, то отключится клик по иконкам организаций()
          * {
              suppressObsoleteBrowserNotifier: true,
              yandexMapDisablePoiInteractivity: true,
              suppressMapOpenBlock: true
            }
          *
          * */

          this._myMap = new ymaps.Map(document.querySelector(block), initMapConfig.map);

          this._myMap.behaviors.disable(['scrollZoom']);

          // Установить выделенные области на карте
          // setArea(areaCoords());

          // Карта готова, резолвим
          deferred.resolve();
        });
      }

      return deferred.promise();
    },

    goToPlace(coord, setCenterOffset) {
      // $(el).on('click', function() {
      //   var $parentBlock = $(this).parents('[data-placemark]');
      //   var singleCoord = this.getSingleCoord(coord);
        var singleCoord = coord;

        this._myMap
          .panTo(singleCoord, {
            // Задержка между перемещениями.
            delay: 100,
            duration: 1000
          })
          .then(function() {
            this._myMap.setZoom(14, {
              smooth: 1,
              duration: 300
            }).then(function() {
              this.setMyCenter(coords, 14);
            });
          });
      // });

      // Второй вариант
      /*setTimeout(function() {
        setMyCenter(singleCoord, 14, setCenterOffset);

      }, 500);*/
    },

    getSingleCoord(coord) {
      return coord.split(',').map(function(el) {
        return +el;
      });
    },

    addAllPlacemarks(allCoords) {
      // Создаем коллекцию геообъектов.
      this._myCollection = new ymaps.GeoObjectCollection();

      allCoords.forEach(el => {
        let placemark = new ymaps.Placemark(el.coord, el.balloon, this._initConfig.placemark);
        this._myCollection.add(placemark);
      });

      // Добавляем коллекцию на карту.
      this._myMap.geoObjects.add(this._myCollection);

      if(allCoords.length > 1) {
        // Устанавливаем карте центр и масштаб так, чтобы охватить коллекцию целиком.
        return this._myMap.setBounds(this._myCollection.getBounds(), {
          duration: 500,
          checkZoomRange: true
        });
      }
      else {
        return Promise.resolve();
      }
    },

    setMyCenter(coord, zoom, setCenterOffset) {
      this._myMap.setCenter(coord, zoom, {
        checkZoomRange: true,
        duration: 500
      }).then(function() {
        if(setCenterOffset)
          setCenterOffset(0, 100);
      });
    },

    setCenterOffset(x, y, zoom) {
      x = x || 0,
      y = y || 0,
      zoom = zoom || 10;

      var position = this._myMap.getGlobalPixelCenter();

      this._myMap.setGlobalPixelCenter([position[0] + x, position[1] + y], zoom, {
        duration: 1000,
        checkZoomRange: true
      });
    },

    openBalloon(coords) {
      this._myMap.balloon.open(coords[0].coord, {content: coords[0].balloon.balloonContentBody}, {
        closeButton: true
        //minWidth: 465,
        //offset: [0, -40],
        //maxHeight: 10
      });
    },

    closeBalloon(coords) {
      this._myMap.balloon.close(coords[0].coord, {content: coords[0].balloon.balloonContentBody});
    },

    areaCoords() {
      return {
        coords: [
          {
            coord: [
              // Краснодар, ЖК Губернский
              [45.077529820186484, 39.03640858860748],
              [45.06912180485666, 39.03655879231232],
              [45.06912180485666, 39.04044263096587],
              [45.07760583634422, 39.04029242726106],
              [45.077529820186484, 39.03640858860748]
            ],
            hint: {
              hintContent: "Микрорайон Губернский"
            }
          },
          {
            coord: [
              // Краснодар, ЖК Панорама
              [45.048034, 39.026824],
              [45.049033, 39.027302],
              [45.049344, 39.027553],
              [45.051100999999996, 39.028636],
              [45.051488, 39.028855],
              [45.052116, 39.029105],
              [45.053888, 39.029363],
              [45.05439, 39.02933],
              [45.054352, 39.031819],
              [45.052824, 39.031856],
              [45.052853999999996, 39.034706],
              [45.045939999999995, 39.034785],
              [45.045881, 39.030622],
              [45.046005, 39.030606],
              [45.046327, 39.030139],
              [45.046288, 39.026863999999996]
            ],
            hint: {
              hintContent: "Микрорайон Панорама"
            }
          }
        ]
      };
    },

    // Опции многоугольника - должно быть в конфиге инита карты
    /*polygon: {
      // - флаг использования заливки
      fill: true,
      // - флаг отрисовки контурной линии
      stroke: true,
      // - ширина линии
      strokeWidth: 2,
      // - цвет и прозрачность линии
      strokeColor: "E27D7D88", // две последние цифры - прозрачность
      // - цвет и прозрачность заливки
      fillColor: "ff000035" // две последние цифры - прозрачность
    }*/

    // Алгоритм работы - ищем на яндекс картах нужный район

    // https://yandex.ru/maps/35/krasnodar/?l=map&ll=39.036531%2C45.048053&mode=search&sll=39.031161%2C45.049342&source=wizgeo&text=%D0%A0%D0%BE%D1%81%D1%81%D0%B8%D1%8F%2C%20%D0%9A%D1%80%D0%B0%D1%81%D0%BD%D0%BE%D0%B4%D0%B0%D1%80%2C%20%D0%BC%D0%B8%D0%BA%D1%80%D0%BE%D1%80%D0%B0%D0%B9%D0%BE%D0%BD%20%D0%9F%D0%B0%D0%BD%D0%BE%D1%80%D0%B0%D0%BC%D0%B0&utm_medium=maps-desktop&utm_source=serp&z=15.05
    // В исходном коде страницы берем набор координат для области этого района -> ctrl+f -> Polygon
    // Координаты в каждом массиве нужно поменять местами: copy(arr.map(function(el) { return el.reverse(); }));

    // Если нет выделенной области от яндекса, тогда используем конструктор карт https://yandex.ru/map-constructor/
    // При сохранении карты нужно выбрать экспорт в файл и там будут координаты

    // todo потестить
    /**
     * Установить выделение областей на основе координат
     * @param coordsObj {Object}
     */
    setArea(coordsObj) {
      if(coordsObj.coords.length) {
        coordsObj.coords.forEach(coordArr => {

          let polygon = new ymaps.Polygon(
            [coordArr.coord ],
            {hintContent: coordArr.hint.hintContent},
            this._initConfig.polygon
          );

          this._myMap.geoObjects.add(polygon);
        });
      }
    },

    showMap(event) {
      let elem = event.target;
      let elemClass = $(elem).attr('class').replace('-circle', '');
      if (elemClass === 'main__section-map') {
          this.disableScroll();
          $('.main__menu-side').css('transform', 'translateX(-300px)');
      }
      $('.' + elemClass + '-block').css('opacity', '0');
      setTimeout(() => {
          $('.' + elemClass + '-block').css('z-index', '-1');
          $('.' + elemClass + '-circle').css('z-index', '-1');
          $('.' + elemClass + '-button').css('opacity', '1');
      }, 200);
    },

    hideMap(event, coords) {
        this.goToPlace(coords.map.center, coords.map.center);
        let elem = event.target;
        let elemClass = $(elem).attr('class').replace('-button', '');
        setTimeout(() => {
            $('.' + elemClass + '-block').css('z-index', '2000');
            $('.' + elemClass + '-circle').css('z-index', '3000');
            $('.' + elemClass + '-button').css('opacity', '0');
            setTimeout(() => {
                $('.' + elemClass + '-block').css('opacity', '1');
                if (elemClass === 'main__section-map') {
                    $('.main__menu-side').css('transform', 'translateX(0)');
                    this.initSlide();
                }
            }, 200);
        }, 300);
    }
  },
};

<template lang="pug">
  header.header(v-if="header")
    .page__wr
      .header__top
        .header__top-left
          router-link.header__logo-wr(to="/")
            img.header__logo(src="@/assets/img/logo.svg" alt="")
          .header__search-wr
            .header__search
              form.header__search-form
                SvgIcon(name="search", width="19", height="19" class="header__search-ico")
                input.header__search-input.js-header__search-input(:value="inputStr", v-on:input="evt=>inputStr=evt.target.value", name="query", autocomplete="off")
              .header__search-popup.js-header__search-popup
                .header__search-popup-content.js-header__search-popup-content
                  .header__search-popup-loader-wr(:class="{'header__search-popup-loader-wr--show': loader}")
                    .header__search-popup-loader
                  .header__search-popup-items(v-if="items.length")
                    a.header__search-popup-item(v-for="item in items", :key="item.id", :href="item.link.href")
                      .header__search-popup-item-title {{item.title}}
                  .header__search-popup-not-found.js-header__search-popup-not-found(v-else)
                    .header__search-popup-not-found-text Ничего не найдено
        .header__top-right
          .header__address(v-if="header.address")
            .header__address-item(v-for="item in header.address", :key="item.id", v-html="item.text")
          .header__phone(v-if="header.phone")
            a.header__phone-item(v-for="item in header.phone", :key="item.id", :href="item.link") {{item.title}}
      .header__bottom
        MenuTop(:items="menuTopItems")
      .header__configurator-wr(v-if="header.configurator")
        router-link.header__configurator(:to="header.configurator.link")
          //SvgIcon(name="cogwheels", width="25", height="25" class="header__configurator-ico")
          .header__configurator-text {{header.configurator.text}}
</template>

<script>
  import '@/components/common/header/header.styl';

  import EventBus from '@/eventBus';

  import MenuTop from '@/components/common/menuTop/menuTop.vue';
  import {AjaxHelpersVue} from '@/mixins/AjaxHelpersVue';
  import SvgIcon from '@/components/common/svgIcon/SvgIcon.vue';

  export default {
    name: 'Header',

    mixins: [AjaxHelpersVue],

    data() {
      return {
        header: null,
        menuTopItems: [],
        items: '',
        loader: false,
        ps: null,
        inputStr: '',
        search: null
      }
    },

    components: {
      MenuTop,
      SvgIcon
    },

    created() {
      // Данные страницы загружены
      EventBus.$on('pageLoaded', res => {
        let path = this.$route.path || '';

        if(!this.header && this.menuTopItems.length === 0) {
          this.header = res.header;
          this.menuTopItems = res.menuTop.items;
        }

        this.$nextTick(() => {
          this.setInitialActiveClass(path);
        });

        this.search = this.debounce(this.request, 300);
        this.$nextTick(function() {
          this.initInput();
        });
      });

      this.$nextTick(() => {
        this.setActiveClass();
      });
    },

    watch: {
      inputStr: function() {
        this.search();
      }
    },

    methods: {
      request() {
        if(this.inputStr === '') {
          this.loader = false;
          this.hidePopup();
        }
        else {
          this.loader = true;
          this.showPopup();
          this.destroyScroll();
        }

        var params = {
          'query': this.inputStr
        };

        this.req('Search:getSearchData', {...params}, res => {
          this.loader = false;
          if(this.checkProperty(res.items)) {
            this.items = res.items;
            this.$nextTick(function() {
              this.initScroll();
            });
          }
        }, '', 'localPageRequest');
      },

      checkProperty(prop) {
        return typeof(prop) !== 'undefined' && $.type(prop) !== 'null';
      },

      initScroll() {
        if(this.ps) this.ps.destroy();
        this.ps = new PerfectScrollbar($('.js-header__search-popup-content')[0]);
      },

      destroyScroll() {
        if(this.ps) this.ps.destroy();
        this.items = '';
      },

      showPopup() {
        $('.js-header__search-popup').addClass('header__search-popup--show');
      },

      hidePopup() {
        $('.js-header__search-popup').removeClass('header__search-popup--show');
      },

      initInput() {
        $(document).on('focus', '.js-header__search-input', function() {
          // Отключить скролл экранов на главной странице
          if($('.page__item--active').find('.slide').length) {
            EventBus.$emit('disableSlideScroll');
          }

          $('body').addClass('overflow');
          $('.overlay').addClass('overlay--show');
          $('.js-header__search-input').addClass('header__search-input--focus');
          $('.header__search-ico').addClass('header__search-ico--focus');
          if($(this).val() !== '') {
            $('.js-header__search-input').addClass('header__search-input--focus');
            $('.header__search-ico').addClass('header__search-ico--focus');
            $('.js-header__search-popup').addClass('header__search-popup--show');
          }
        });

        $(document).on('click', function() {
          if($(event.target).closest('.js-header__search-popup').length || $(event.target)
            .closest('.js-header__search-input').length)
            return;

          // Включить скролл экранов на главной странице
          if($('.page__item--active').find('.slide').length) {
            EventBus.$emit('enableSlideScroll');
          }

          $('body').removeClass('overflow');
          $('.overlay').removeClass('overlay--show');
          $('.js-header__search-input').removeClass('header__search-input--focus');
          $('.header__search-ico').removeClass('header__search-ico--focus');
          $('.js-header__search-popup').removeClass('header__search-popup--show');
        });
      },

      debounce(func, wait, immediate) {
        var timeout, args, context, timestamp, result;
        if(null == wait) wait = 100;

        function later() {
          var last = Date.now() - timestamp;

          if(last < wait && last >= 0) {
            timeout = setTimeout(later, wait - last);
          }
          else {
            timeout = null;
            if(!immediate) {
              result  = func.apply(context, args);
              context = args = null;
            }
          }
        }

        var debounced = function() {
          context     = this;
          args        = arguments;
          timestamp   = Date.now();
          var callNow = immediate && !timeout;
          if(!timeout) timeout = setTimeout(later, wait);
          if(callNow) {
            result  = func.apply(context, args);
            context = args = null;
          }

          return result;
        };

        debounced.clear = function() {
          if(timeout) {
            clearTimeout(timeout);
            timeout = null;
          }
        };

        debounced.flush = function() {
          if(timeout) {
            result  = func.apply(context, args);
            context = args = null;

            clearTimeout(timeout);
            timeout = null;
          }
        };

        return debounced;
      },

      /*popupLocal(type, action) {
        this.popup(type, action);
      },*/

      setInitialActiveClass(path) {
        let pathArr = path.split('/');

        $('.menu-top__item').removeClass('menu-top__item--active');

        if(pathArr[1].length) {
          $(`.menu-top__item[href*='${pathArr[1]}']`).addClass('menu-top__item--active');
        }
        else {
          $('.menu-top__item').eq(0).addClass('menu-top__item--active');
        }
      },

      setActiveClass() {
        $(document).on('click', '.menu-top__item', function() {
          $('.menu-top__item')
            .filter('.menu-top__item--active')
            .removeClass('menu-top__item--active');

          $(this).addClass('menu-top__item--active');
        });
      }
    }

  }
</script>

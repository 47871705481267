<template lang="pug">
  .about(v-if="content")
    .page__wr
      .about__nav-wr
        ul.about__nav(v-if="content.nav")
          li.about__nav-item(v-for="(item, index) in content.nav" :key="index" :class="{'about__nav-item--active': index == content.nav.length-1}")
            router-link.about__nav-item-button(:to="item.link") {{ item.name }}
        .about__nav-right(v-if="content.navRight")
          router-link.about__nav-right-item(v-for="(item, index) in content.navRight" :key="index" :to="item.link")
            SvgIcon(:name="item.icon", width="35", height="35", class="about__nav-right-item-ico")
            .about__nav-right-item-text {{ item.name }}
      .about__main
        .about__main-slider.about__main-slider--history(v-if="content.slider")
          .swiper-container
            .swiper-wrapper
              .swiper-slide.about__main-swiper-slide(v-for="image in content.slider" :key="image.id")
                .about__main-slider-item
                  .about__main-slider-item-layout
                  .about__main-slider-item-img-wr(:data-src="image.path")
                    img.about__main-slider-item-img.about__main-slider-item-img--hide(:src='image.path' :alt="image.name")
                    .about__main-slider-item-background
          .about__main-slider-navigation
            .swiper-button-prev
              SvgIcon(class='about__main-slider-ico' name="arrow" width="22" height="34")
            .about__main-slider-pagination.swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets
            .swiper-button-next
              SvgIcon(class='about__main-slider-ico' name="arrow" width="22" height="34")
        .about__main-text-wr
          .about__main-text(v-if="content.text", v-html="content.text")
    Footer
</template>

<script>
  import EventBus from '@/eventBus';

  import '@/components/about/about.styl';
  import SvgIcon from '@/components/common/svgIcon/SvgIcon.vue';
  import {AjaxHelpersVue} from '@/mixins/AjaxHelpersVue';
  import {SliderHelpersVue} from "@/mixins/SliderHelpersVue";
  import {ScrollbarHelpers} from "@/mixins/ScrollbarHelpers";
  import {ImagePreloaderVue} from '@/mixins/ImagePreloaderVue';
  import Footer from '@/components/common/footer/Footer';

  export default {
    name: 'History',
    mixins: [AjaxHelpersVue, SliderHelpersVue, ScrollbarHelpers, ImagePreloaderVue],

    data() {
      return {
        content: null
      }
    },

    components: {
      SvgIcon,
      Footer
    },

    created() {
      // Загрузка данных для страницы
      EventBus.$on('loadHistoryPage', params => {
        this.request(params.url);
      });
    },

    methods: {
      request(url) {
        this.req('AboutPage:getHistoryPage', {url}, res => {
          this.content = res.content;
          this.$nextTick(() => {
            this.checkOverflow(url);
            this.initSlider('.about__main-slider--history', 'about__main-slide');
            this.imgPreloader('about__main-slider-item-img-wr', 'about__main-slider-item-img', '', 250);
          })
        }, 'history');
      },
    }
  }
</script>

<template lang="pug">
  .conf-page-noresult(v-if="content")
    .page__wr
      .conf-page-noresult__content
        h1.conf-page-noresult__title
          | {{h1}}
        .conf-page-noresult__subtitle(v-if="content.subtitle")
          | {{content.subtitle}}
        .conf-page-noresult__content-bottom-btns-wr
          a.conf-page-noresult__content-top-info-right-btn-info(v-if="content.btnListDownload", :href="content.btnListDownload.lnk" download)
            SvgIcon.conf-page-noresult__content-top-info-right-btn-info-ico(name="download1" width="26" height="26")
            .conf-page-noresult__content-top-info-right-btn-info-title
              | {{content.btnListDownload.title}}
          router-link.conf-page-noresult__content-top-info-right-btn-online(v-if="content.btnListWrite", :to="content.btnListWrite.lnk" download)
            .conf-page-noresult__content-top-info-right-btn-online-title
              | {{content.btnListWrite.title}}

    Footer
</template>

<script>
  import EventBus from '@/eventBus';
  import Footer from '@/components/common/footer/Footer.vue';
  import SvgIcon from '@/components/common/svgIcon/SvgIcon.vue';

  import '@/components/configuratorPageNoResult/configuratorPageNoResult.styl';
  import {AjaxHelpersVue} from '@/mixins/AjaxHelpersVue';

  export default {
    name: 'ConfiguratorPageNoResult',

    mixins: [AjaxHelpersVue],

    data() {
      return {
        content: null
      }
    },

    components: {
      SvgIcon,
      Footer
    },

    created() {
      // Загрузка данных для страницы
      EventBus.$on('loadConfiguratorPageNoFound', params => {
        this.request(params.url);
      });
    },

    methods: {
      request(url) {
        this.req('ConfiguratorPage:getConfiguratorPageNoResult', {url}, res => {
          this.content = res.content;

          if(this.checkProperty(res.content)) {
            this.$nextTick(() => {

              this.checkOverflow(url);
            })
          }
        }, 'conf-page-noresult');
      }
    }
  }
</script>

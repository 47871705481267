<template lang="pug">
  .main__section(v-if="section2")
    .main__section-slider.main__section-slider--two(v-if="section2.slider")
      .swiper-container
        .swiper-wrapper
          .swiper-slide.main__section-swiper-slide(v-for="(image, index) in section2.slider" :key="index")
            .main__section-slider-item
              .main__section-slider-item-layout
              .main__section-slider-item-img-wr(:data-src="image.path")
                img.main__section-slider-item-img.main__section-slider-item-img--hide(:src='image.path' :alt="image.name")
      .main__section-slider-navigation
        .swiper-button-prev
          SvgIcon(class='main__section-slider-ico' name="arrow" width="22" height="34")
        .main__section-slider-pagination.swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets
        .swiper-button-next
          SvgIcon(class='main__section-slider-ico' name="arrow" width="22" height="34")
    .main__section-block
      h2.main__section-headline(v-if="section2.subtitle", v-html="section2.subtitle")
      .main__section-scroll.main__section-scroll--two
        .main__section-text(v-if="section2.text", v-html="section2.text")
      a.main__section-button(v-if="section2.button", :href="section2.button.link")
        .main__section-button-text {{section2.button.text}}
    .main__section-popup
      form.main__section-popup-form(v-on:submit.prevent="submit" :class="{'main__section-popup-form--show': !formSend}")
        h2.main__section-popup-form-legend Оставить заявку
        .main__section-popup-form-block
          input.main__section-popup-form-input(type="text" placeholder="Ваше имя" v-model="name" required)
          input.main__section-popup-form-input(type="email" placeholder="E-mail" v-model="email" required)
        input.main__section-popup-form-input(type="tel" name="phone" v-model="phone" v-mask="'+7(###)-###-##-##'" placeholder="+7(___)-___-__-__" required)
        textarea.main__section-popup-form-textarea(placeholder="Комментарий" v-model="comment" required)
        button.main__section-popup-form-button(type="submit")
          .main__section-popup-form-button-text Отправить
        .main__section-popup-form-description
          p.main__section-popup-form-description-text Нажимая кнопку «Отправить», я даю согласие на
            br
            router-link(to="/politika-konfidencialnosti" target="_blank").main__section-popup-form-description-text--link  обработку персональных данных
      .main__section-popup-form-send(:class="{'main__section-popup-form-send--show': formSend}")
        h5.main__section-popup-form-send-text Ваша заявка успешно отправлена!
</template>

<script>
  import EventBus from '@/eventBus';
  import SvgIcon from '@/components/common/svgIcon/SvgIcon.vue';
  import {SliderHelpersVue} from "@/mixins/SliderHelpersVue";
  import {ScrollbarHelpers} from "@/mixins/ScrollbarHelpers";
  import {ImagePreloaderVue} from '@/mixins/ImagePreloaderVue';
  import {FormHelpersVue} from "@/mixins/FormHelpersVue";

  export default {
    name: 'SectionTwo',

    mixins: [SliderHelpersVue, ScrollbarHelpers, ImagePreloaderVue, FormHelpersVue],

    props: ['section2'],

    data() {
      return {
        content: null,
        name: '',
        phone: '',
        email: '',
        comment: '',
        formSend: false
      }
    },

    components: {
      SvgIcon
    },

    created() {
      this.$nextTick(() => {
        this.initSlider('.main__section-slider--two', '.main__section-slider--two');
        this.customScrollbarInit('.main__section-scroll--two');
        this.imgPreloader('main__section-slider-item-img-wr', 'main__section-slider-item-img', '', 250);
      });
    },

    methods: {

    },
  }
</script>

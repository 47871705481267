<template lang="pug">
  .privacy-policy(v-if="content")
    .privacy-policy__bg(style="background-image: url(img/bg.png)")
    .page__wr
      ul.privacy-policy__nav(v-if="content.nav")
        li.privacy-policy__nav-item(v-for="(item, index) in content.nav" :key="index" :class="{'privacy-policy__nav-item--active': index == content.nav.length-1}")
          router-link.privacy-policy__nav-item-button(:to="item.link") {{ item.name }}
      .privacy-policy__main
        .privacy-policy__title(v-if="content.title") {{content.title}}
        .privacy-policy__text(v-if="content.text", v-html="content.text")
    Footer
</template>

<script>
  import EventBus from '@/eventBus';

  import '@/components/privacyPolicy/privacyPolicy.styl';
  import SvgIcon from '@/components/common/svgIcon/SvgIcon.vue';
  import {AjaxHelpersVue} from '@/mixins/AjaxHelpersVue';
  import {SliderHelpersVue} from "@/mixins/SliderHelpersVue";
  import {ScrollbarHelpers} from "@/mixins/ScrollbarHelpers";
  import Footer from '@/components/common/footer/Footer';

  export default {
    name: 'PrivacyPolicy',
    mixins: [AjaxHelpersVue, SliderHelpersVue, ScrollbarHelpers],

    data() {
      return {
        content: null
      }
    },

    components: {
      SvgIcon,
      Footer
    },

    created() {
      // Загрузка данных для страницы
      EventBus.$on('loadPolicyPage', params => {
        this.request(params.url);
      });
    },

    methods: {
      request(url) {
        this.req('PolicyPage:getPolicyPage', {url}, res => {
          this.content = res.content;
          this.$nextTick(() => {
            this.checkOverflow(url);
          })
        }, 'privacy-policy');
      },

    }
  }
</script>

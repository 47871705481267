import EventBus from "../eventBus";

export const SlideHelpers = {
  data() {
    return {
        $slides: '',
        $menuSide: '',
        slidesLength: 0,
        animaGo: true,
        currentMenuId: 0,
    }
  },
  // strict: true,

  methods: {
      initSlide() {
          if($(window).width() < 1280) {
              return false;
          }

          this.$slides = $('.slide');
          if (!this.$slides.length) return;

          this.enableScroll();
          this.enableKeyboardNav();
          this.setAnimationEndEvent();

          if ($('.js-menu-side').length) {
              this.initMenuSide();
          }
          this.slidesLength = this.$slides.length;

          if($(location).attr('hash') === '') {
              $('.slide').removeClass('slide--show');
              $('.slide[data-id="'+1+'"]').addClass('slide--show');
          }
      },

      setAnimationEndEvent() {
          this.$slides.on('animationend', () => {
              this.animaGo = true;
          });
      },

      enableKeyboardNav() {
          $(document).keyup((e) => {
              if (e.which === 37) {
                  this.prevSlide();
              }
              else if (e.which === 39) {
                  this.nextSlide();
              }
          });
      },

      enableScroll() {
          $(document).on('wheel.slideCustom', (e) => {
              var div = $(".mCustomScrollbar");
              if (!div.is(e.target) && div.has(e.target).length === 0 || div.has(e.target).hasClass("mCS_no_scrollbar")) {
                  if (!this.animaGo) return;

                  if (e.originalEvent.deltaY < 0) {
                      this.prevSlide();
                      this.changeStyle();

                  }
                  else {
                      this.nextSlide();
                      this.changeStyle();

                  }
              }
          });
      },

      disableScroll() {
          $(document).off('wheel.slideCustom');
      },

      nextSlide() {
          var nextId = this.getNextOrPrevId('next');
          this.setSlide(nextId, 'next');
      },

      prevSlide() {
          var prevId = this.getNextOrPrevId('prev');

          this.setSlide(prevId, 'prev');
      },

      setSlide(targetId, direction) {
          var $targetSlide = this.$slides.filter('[data-id="' + targetId + '"]');

          this.currentMenuId = targetId;

          var directionClass = '';


          if (direction === 'next') {
              directionClass = 'slide--slideUp';
          }
          else if (direction === 'prev') {
              directionClass = 'slide--slideDown';
          }
          else {
              directionClass = 'slide--slideUp';
          }

          this.$slides.removeClass('slide--show slide--slideUp slide--slideDown');
          $targetSlide.addClass('slide--show ' + directionClass);

          if (this.$menuSide.length) {
              this.setMenuSide(targetId);
          }

          if (+targetId === 1) {
              $('.js-btn-up').removeClass('btn-up--show');
          }
          else {
              $('.js-btn-up').addClass('btn-up--show');
          }
      },

      getNextOrPrevId(type) {
          this.currentId = $('.slide--show').attr('data-id') || 1;
          var id = 0;

          if (type === 'next') {
              id = ++this.currentId;

              if (id >= this.slidesLength) {
                  id = this.slidesLength;
              }
              else {
                  this.animaGo = false;
              }
          }
          else if (type === 'prev') {
              id = --this.currentId;

              if (id < 1) {
                  id = 1;
              }
              else {
                  this.animaGo = false;
              }
          }
          else {
              id = 1;
          }

          return id;
      },

      initMenuSide() {
          this.$menuSide = $('.js-menu-side');
          var id = this.$menuSide.find('.main__menu-side-list-item').eq(0).attr('data-id') || 1;

          var initHash = location.hash.replace('#', '');

          var firtsHash =this.$menuSide.find('.main__menu-side-list-item').eq(0).attr('data-hash') || '';

          this.setHash(firtsHash);

          // При загрузки странице есть хеш
          if (initHash) {
              id = this.getIdByHash(initHash);

              this.setSlide(id);
          }

          this.currentMenuId = id;

          this.setMenuSide(id);

          // Клик по боковому меню
          $('.main__menu-side-list-item').on('click', (event) => {
              let elem = event.target;
              var id = $(elem).attr('data-id') || 1;
              var direction = (this.currentMenuId < id) ? 'next' : 'prev';

              this.setSlide(id, direction);
              this.changeStyle();

              this.$menuSide.find('.main__menu-side-list-item--active').removeClass('main__menu-side-list-item--active');
              $(elem).addClass('main__menu-side-list-item--active');
          });
      },

      setMenuSide(targetId) {
          this.$menuSide = $('.js-menu-side');

          this.$menuSide.find('.main__menu-side-list-item--active').removeClass('main__menu-side-list-item--active');

          var $target = this.$menuSide.find('[data-id=' + targetId + ']');
          var hash = $target.attr('data-hash') || '';

          $target.addClass('main__menu-side-list-item--active');

          this.setHash(hash);
      },

      getIdByHash(hash) {
          return $('.js-menu-side').find('.main__menu-side-list-item[data-hash="' + hash + '"]').attr('data-id') || 1;
      },

      setHash(hash) {
          window.history.pushState(null, window.location.hash, '#' + hash);
      },

      changeStyle() {
          let $items = $('.main__menu-side-list-item');
          if($('.slide--show').attr('data-id') == 7) {
              $items.addClass('main__menu-side-list-item--light');
              $('.main__menu-side-list-item--active').addClass('main__menu-side-list-item--active--light');
              $('.main__sections-bg').css('opacity', '0');
          } else {
              $items.removeClass('main__menu-side-list-item--light');
              $items.removeClass('main__menu-side-list-item--active--light');
              $('.main__sections-bg').css('opacity', '1');
          }
      }
}
};

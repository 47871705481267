<template lang="pug">
  .page-404(v-if="content")
    .page__wr
      .page-404__content
        ul.page-404__nav(v-if="content.nav")
          li.page-404__nav-item(v-for="(item, index) in content.nav" :key="index" :class="{'page-404__nav-item--active': index == content.nav.length-1}")
            router-link.page-404__nav-item-button(:to="item.link") {{ item.name }}
        .page-404__center
          .page-404__title
            .page-404__title-left 404
            .page-404__title-right Извините!#[br]Страница не найдена
          router-link.page-404__button(to="/")
            .page-404__button-text Вернуться на главную
    Footer
</template>

<script>
  import EventBus from '@/eventBus';

  import '@/components/page404/page404.styl';
  import {AjaxHelpersVue} from '@/mixins/AjaxHelpersVue';

  // @ is an alias to /src
  import Footer from '@/components/common/footer/Footer.vue';

  export default {
    name: 'Page404',

    mixins: [AjaxHelpersVue],

    data() {
      return {
        content: null
      }
    },

    components: {
      Footer
    },

    created() {
      // Загрузка данных для страницы
      EventBus.$on('load404Page', (params) => {
        this.request(params.url);
      });
    },

    methods: {
      request(url) {
        // Нейминг должен быть такой, без цифр в начале
        this.req('NotFoundPage:getNotFoundPage', {url: '*'}, res => {
          if(this.checkProperty(res.content)) {
            this.content = res.content;
            this.checkOverflow(url);
          }
        }, 'page-404');
      }
    }
  }
</script>

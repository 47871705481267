<template lang="pug">
  nav.main__menu-side.js-menu-side
    ul.main__menu-side-list
      li.main__menu-side-list-item(v-for="item in menu" :key="item.id" :data-id="item.id" :data-hash="item.link")
        .main__menu-side-list-item-line
        SvgIcon(name="menu-side", width="28", height="28" class="main__menu-side-list-item-ico")
        .main__menu-side-list-item-link {{ item.title }}

</template>

<script>
  import EventBus from '@/eventBus';

  import '@/components/main/menuSide/menuSide.styl';
  import {AjaxHelpersVue} from '@/mixins/AjaxHelpersVue';
  import SvgIcon from '@/components/common/svgIcon/SvgIcon.vue';


  export default {
    name: 'MenuSide',

    mixins: [AjaxHelpersVue],

    props: ['menu'],

    data() {
      return {}
    },

    components: {
      SvgIcon
    },

    created() {

    },

    methods: {}

  }
</script>
